import { useState } from "react";
import { toTitleCase } from "../../../Utils/index";
export const References = ({ personalReferences = [] }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Referencias
      </p>
      {open && (
        <div>
          {personalReferences.map((reference, i) => (
            <div className="notification">
              <p className="my-1 is-size-5 has-text-weight-bold">
                Referencia {i + 1}
              </p>
              <p className="my-1 is-size-5">
                Nombre (s):
                <b className="ml-2 has-text-primary">
                  {toTitleCase(reference.name)}
                </b>
              </p>

              <p className="my-1 is-size-5">
                Relación:
                <b className="ml-2 has-text-primary">
                  {toTitleCase(reference.relationship)}
                </b>
              </p>

              <p className="my-1 is-size-5">
                Ocupación:
                <b className="ml-2 has-text-primary">
                  {toTitleCase(reference.job)}
                </b>
              </p>

              <p className="my-1 is-size-5 is-flex is-flex-wrap-wrap">
                Correo electrónico:
                <b className="ml-2 has-text-primary">{reference.email}</b>
              </p>

              <p className="my-1 is-size-5">
                Teléfono:
                <b className="ml-2 has-text-primary">{reference.phone}</b>
              </p>

              <p className="my-1 is-size-5 has-text-weight-bold">
                Historial de llamadas:
              </p>

              {reference.history.map((call, i) => (
                <div>
                  <p className="my-1 is-size-5 is-uppercase has-text-weight-bold	">
                    Lamada {i + 1}
                  </p>
                  <p className="my-1 is-size-5">
                    ¿Contestó?
                    <b className="ml-2 has-text-primary">
                      {call.answered ? "Si" : "NO"}
                    </b>
                  </p>
                  {call.answered && (
                    <>
                      <p className="my-1 is-size-5">
                        ¿Conoce al referido?
                        <b className="ml-2 has-text-primary">{call.known}</b>
                      </p>
                      <p className="my-1 is-size-5">
                        Tiempo de conocerle
                        <b className="ml-2 has-text-primary">{call.meetTime}</b>
                      </p>
                      <p className="my-1 is-size-5">
                        ¿Cuál es su relación con el referido?
                        <b className="ml-2 has-text-primary">
                          {toTitleCase(call.relationship)}
                        </b>
                      </p>
                      <p className="my-1 is-size-5">
                        ¿Lo considera solvente económicamente?
                        <b className="ml-2 has-text-primary">
                          {call.financialSolvency}
                        </b>
                      </p>
                      <p className="my-1 is-size-5">
                        ¿Lo considera una persona responsable?
                        <b className="ml-2 has-text-primary">
                          {call.isResponsible}
                        </b>
                      </p>
                      <p className="my-1 is-size-5">
                        ¿Lo recomienda como inquilino?
                        <b className="ml-2 has-text-primary">
                          {call.recomended}
                        </b>
                      </p>
                      <p className="my-1 is-size-5">
                        ¿Sabe cuál es su activad laboral?
                        <b className="ml-2 has-text-primary">
                          {call.jobActivity}
                        </b>
                      </p>
                    </>
                  )}
                </div>
              ))}
              {reference.comments && (
                <>
                  <p className="my-1 is-size-5 has-text-weight-bold">
                    Comentarios
                  </p>
                  <b className="ml-2 has-text-primary">{reference.comments}</b>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
