import axios from 'axios';
import { API } from '../../api';

export const STS = ['AS', 'BC', 'BS', 'CC', 'CS', 'CH', 'DF', 'CL', 'CM', 'DG', 'GT', 'GR', 'HG', 'JC', 'MC', 'MN', 'MS', 'NT', 'NL', 'OC', 'PL', 'QO', 'QR', 'SL', 'SR', 'TC', 'TS', 'TL', 'VZ', 'YN', 'ZS']

export const states = {
'AS': "AGUASCALIENTES",
'BC': "BAJA CALIFORNIA",
'BS': "BAJA CALIFORNIA SUR",
'CC': "CAMPECHE",
'CS': "CHIAPAS",
'CH': "CHIHUAHUA",
'DF': "CIUDAD DE MÉXICO",
'CL': "COAHUILA",
'CM': "COLIMA",
'DG': "DURANGO",
'GT': "GUANAJUATO",
'GR': "GUERRERO",
'HG': "HIDALGO",
'JC': "JALISCO",
'MC': "MÉXICO",
'MN': "MICHOACÁN ",
'MS': "MORELOS",
'NT': "NAYARIT",
'NL': "NUEVO LEÓN",
'OC': "OAXACA",
'PL': "PUEBLA",
'QO': "QUERÉTARO",
'QR': "QUINTANA ROO",
'SL': "SINALOA",
'SR': "SONORA",
'TC': "TABASCO",
'TS': "TAMAULIPAS",
'TL': "TLAXCALA",
'VZ': "VERACRUZ",
'YN': "YUCATÁN",
'ZS': "ZACATECAS",
}

export const statesNames = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Chiapas',
  'Chihuahua',
  'Ciudad De México',
  'Coahuila',
  'Colima',
  'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'México',
  'Michoacán ',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
]

const API_VERSION = API.server

export const GetCurrentRenter = () => axios.get(`${API_VERSION}/renter`);

export const Models = {
  generalData: 'generalData',
  economyActivity: 'economyActivity',
  destinationProperty: 'destinationProperty',
  guarantorData: 'guarantorData',
  guarantorProperty: 'guarantorProperty',
  renter: 'renter',
}

export const UpdateField = (data) => {
  let renterID = localStorage.getItem('renterID');
  return axios.put(`${API_VERSION}/renter/update-data`, { renterID: renterID, ...data})
}

export const CreateReference = (data) => {
  let renterID = localStorage.getItem('renterID');
  return axios.put(`${API_VERSION}/renter/add-reference`, { renter: renterID, ...data})
}

export const GetPersonalReferences = (data) => {
  let renterID = localStorage.getItem('renterID');
  return axios.get(`${API_VERSION}/references/${renterID}`)
}

export const UpdateReference = (id, data) => {
  return axios.put(`${API_VERSION}/references/${id}`, data)
}

export const GetRenterProcedures = () => axios.get(`${API_VERSION}/renter/procedures`);
export const GetProcedure = (id) => axios.get(`${API_VERSION}/renter/procedure/${id}`);

export const GetProcedureDetail = (id) => { 
  return axios.get(`${API_VERSION}/renter/procedure-detail/${id}`);
}

export const GetProcedureDetailByToken = () => { 
  return  axios.get(`${API_VERSION}/renter/procedure-with-auth`);
}

export const CreateCustomerIfNotExist = (data) => axios.post(`${API_VERSION}/renter/customer`, data)

export const AttachPayMentAndCharge = (data) => axios.post(`${API_VERSION}/renter/attach/payment`, data)

export const SaveBillingDetails = (data) => axios.post(`${API_VERSION}/renter/tax-data`, data)

export const Pay = (data) => axios.post(`${API_VERSION}/payments/pay`, data)

export const SendNip = () => axios.get(`${API_VERSION}/renter/nip`)

export const GenerateQrLink = (procedureId) => axios.get(`${API_VERSION}/renter/qr-link/${procedureId}`)

export const AuthNip = (data) => axios.post(`${API_VERSION}/renter/nip`, data);

export const SendProcedureLink = (data) => axios.post(`${API_VERSION}/procedures/send-link`, data);

export const UploadFile = (data) => {
  let renterID = localStorage.getItem('renterID');
  data.append('renterID', renterID);
  return axios.post(`${API_VERSION}/renter/upload-file`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const GetSection = (data) => {
  let renterID = localStorage.getItem('renterID');
  return axios.post(`${API_VERSION}/renter/section`, { renterID: renterID, ...data})
}

export const GetIssues = () => {
  let renterID = localStorage.getItem('renterID');
  return axios.get(`${API_VERSION}/renter/issues/${renterID}`)
}

export const GetReferencedBy = (id) => {
  return axios.get(`${API_VERSION}/real-estate/${id}`)
}


export const GetFileURL = (data) => axios.post(`${API_VERSION}/renter/download-file`, data)
