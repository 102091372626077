import { useState } from "react";

export const GuarantorProperty = ({
  guarantorProperty,
  renter,
  openFile,
  ownerFile,
  houseDeedsFile,
  registrationTicketFile,
  predialPaymentFile,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div class="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Inmueble del fiador
      </p>
      {open && (
        <div>
          {guarantorProperty?.notes && (
            <div className="notification">
              Notas de nuestro agente: <br />
              <b>{guarantorProperty?.notes}</b>
            </div>
          )}
          <p className="has-text-weight-bold is-size-5">
            Documentación proporcionada:
          </p>
          <div className="buttons">
            {ownerFile?.map((f, i) => (
              <button
                className="button is-light is-primary"
                onClick={() => {
                  openFile([f]);
                }}
              >
                Comprobante de titularidad {i + 1}
              </button>
            ))}
            {guarantorProperty?.clgFile?.map((f, i) => (
              <button
                className="button is-light is-primary"
                onClick={() => {
                  openFile([f]);
                }}
              >
                CLG {i + 1}
              </button>
            ))}
          </div>
          <div className="buttons">
            {houseDeedsFile?.map((f, i) => (
              <button
                className="button is-light is-primary"
                onClick={() => {
                  openFile([f]);
                }}
              >
                Escrituras {i + 1}
              </button>
            ))}
            {registrationTicketFile?.map((f, i) => (
              <button
                className="button is-light is-primary"
                onClick={() => {
                  openFile([f]);
                }}
              >
                Boleta {i + 1}
              </button>
            ))}
            {predialPaymentFile?.map((f, i) => (
              <button
                className="button is-light is-primary"
                onClick={() => {
                  openFile([f]);
                }}
              >
                Pago de predial {i + 1}
              </button>
            ))}
          </div>
          <p className="my-1 is-size-5">
            Domicilio:&nbsp;
            <b className="has-text-primary">{guarantorProperty.address}</b>
          </p>
          <p className="my-1 is-size-5">
            Reporta algún gravamen:&nbsp;
            <b className="has-text-primary">{guarantorProperty.assessment}</b>
          </p>
          <p className="my-1 is-size-5">
            Tipo de gravamen:&nbsp;
            <b className="has-text-primary">
              {guarantorProperty.assessmentName}
            </b>
          </p>
        </div>
      )}
    </div>
  );
};
