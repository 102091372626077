import React, { useContext, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router";
import {
  GetProcedure,
  GenerateQrLink,
  SendProcedureLink,
} from "../../services/Renter";
import useCopyToClipboard from "../../hooks/useClipBoard";
import { CheckIcon } from "./icons/check";
import { AlertIcon } from "./icons/alert";

function toTitleCase(str = "") {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const UsagesLabels = [
  "Habitacional",
  "Comercial",
  "Oficina",
  "Industrial",
  "Bodega",
  "Agrícola",
  "Mixto",
];

const OtherEntryLabels = {
  ingreso_de_la_pareja: "Ingreso de la pareja",
  negocio: "Negocio",
  empleo: "Empleo",
  otros: "Otros",
};
/*
 * GenPdf
 * Add description for your component
 */
export const GenPdf = () => {
  let { id } = useParams();
  const [value, copy] = useCopyToClipboard();
  const [procedureData, setProcedureData] = useState(null);
  const [qrToken, setToken] = useState("");
  const [emailToSend, setEmailToSend] = useState("");
  const [sending, setSending] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Enviar");
  useEffect(() => {
    GenPdf.getFile();
    getQrLink();
  }, []);

  const getQrLink = async () => {
    const { data: token } = await GenerateQrLink(id);
    setToken(token);
  };

  GenPdf.getFile = async () => {
    const { data } = await GetProcedure(id);
    const {
      user,
      generalData,
      economyActivity,
      destinationProperty,
      guarantorData,
      guarantorProperty,
      personalReferences,
    } = data?.renter;
    setProcedureData({
      renterName: toTitleCase(
        `${generalData?.firstName} ${generalData?.secondName ?? ""} ${
          generalData?.lastName1
        } ${generalData?.lastName2}`
      ),
      birthDateNPlace: toTitleCase(
        `${generalData?.birthdate}  ${generalData?.birthPlace}`
      ),
      curp: generalData?.curp?.toUpperCase(),
      address: `${generalData?.street?.toUpperCase()} ${generalData?.number?.toUpperCase()} ${generalData?.city?.toUpperCase()} ${generalData?.state.toUpperCase()}`,
      phone: user?.phone,
      email: user?.email,
      nationality: toTitleCase(generalData?.nationality),
      martialStatus: toTitleCase(generalData?.maritialStatus),
      creditHistoryQualification:
        generalData?.creditHistoryQualification ?? "No Encontrado",
      legalHistoryQualification:
        generalData?.legalHistoryQualification ?? "No Encontrado",
      mainEntry: toTitleCase(economyActivity?.mainEntry),
      jobSource: toTitleCase(economyActivity?.workSource),
      jobAddres: toTitleCase(economyActivity?.address ?? ""),
      jobPhone: economyActivity?.jobPhone,
      entry: `$ ${economyActivity?.mensualEntry}`,
      otherEntry: OtherEntryLabels[economyActivity?.otherEntry],
      otherEntryName: economyActivity?.otherEntryName,
      otherEntryAmmount: economyActivity?.otherEntryAmmount,
      references: personalReferences, // TODO
      usage:
        destinationProperty?.usage !== -1
          ? UsagesLabels[destinationProperty?.usage]
          : "Otro",
      persons: destinationProperty?.totalPeople,
      hasPets: destinationProperty?.hasPets ? "Sí" : "No",
      petType: destinationProperty?.petType,
      guarantorName: toTitleCase(guarantorData?.name),
      guarantorCurp: guarantorData?.curp?.toUpperCase(),
      guarantorAddress: toTitleCase(guarantorData?.address),
      guarantorBirthDateNPlace: toTitleCase(
        `${guarantorData.birthdate} ${guarantorData?.birthPlace} `
      ),
      guarantorPhone: guarantorData?.phone,
      guarantorPropertyAddress: guarantorProperty?.address,
      guarantorActivity: "N/D",
      ownerFile: "Predial",
      legalHistory: generalData?.legalHistory,
      creditHistory: generalData?.creditHistory,
      assessment: guarantorProperty?.assessment,
      assessmentName: toTitleCase(guarantorProperty?.assessmentName ?? ""),
    });
    /*setTimeout(() => {
      window.print();
    }, 5000)*/
  };

  const print = () => {
    const input = document.getElementById("divToPrint");
    var w = input.offsetWidth;
    var h = input.offsetHeight;
    html2canvas(input).then((canvas) => {
      var myImage = canvas.toDataURL("image/jpeg,1.0");
      var pdf = new jsPDF();
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(myImage, "JPEG", 0, 0, width, height);
      pdf.save(`comprobante.pdf`);
    });
  };

  const sendUrl = async (url) => {
    setSending(true);
    const { data: success } = await SendProcedureLink({
      link: url,
      email: emailToSend,
    });
    setSending(false);
    setEmailToSend("");
    setButtonLabel("Enviado!");
  };

  return procedureData !== null ? (
    <>
      <section className="hero is-small pt-6 has-text-centered is-fullwidth no-show-on-print">
        <div className="hero-body is-fullwidth mt-3">
          <div>
            <p className="title has-text-primary">
              Descargar (vigente por 7 días)
            </p>
            <p className="subtitle mt-3 is-flex is-justify-content-center">
              <button className="button is-primary" onClick={print}>
                Descargar
              </button>
              <button
                className="button is-info ml-2"
                onClick={() => {
                  copy(`https://inquilinos.invix.mx/investigacion/${qrToken}`);
                }}
              >
                Copiar Link
              </button>
              <div class="field has-addons ml-2">
                <div class="control">
                  <input
                    class="input"
                    type="email"
                    value={emailToSend}
                    onChange={({ target: { value } }) => setEmailToSend(value)}
                    placeholder="Enviar link a correo"
                  />
                </div>
                <div class="control">
                  <button
                    class={`button is-link ${sending ? "is-loading" : ""}`}
                    onClick={() => {
                      sendUrl(`https://invix.mx/investigacion/${qrToken}`);
                    }}
                  >
                    {buttonLabel}
                  </button>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>
      <main
        id="divToPrint"
        className="has-background-white"
        style={{
          width: "203mm",
          height: "297mm",
          margin: "auto",
          padding: "16px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="165"
          height="54"
          viewBox="0 0 273 89"
        >
          <defs>
            <clipPath id="clip-logo-detail">
              <rect width="273" height="89" />
            </clipPath>
          </defs>
          <g id="logo-detail" clip-path="url(#clip-logo-detail)">
            <g
              id="Grupo_26"
              data-name="Grupo 26"
              transform="translate(1.795 -2.646)"
            >
              <g
                id="Grupo_23"
                data-name="Grupo 23"
                transform="translate(87.182 30.032)"
              >
                <g id="Grupo_2" data-name="Grupo 2" transform="translate(0 0)">
                  <path
                    id="Trazado_17"
                    data-name="Trazado 17"
                    d="M8.716,70.4h2.028V45.929H8.716ZM9.753,40.476a1.669,1.669,0,0,0,1.713-1.758,1.713,1.713,0,1,0-3.425,0A1.669,1.669,0,0,0,9.753,40.476ZM38.508,70.4h1.983V56.07c0-7.346-4.417-10.637-9.87-10.637-4.056,0-7.572,1.893-9.014,5.679V45.929H19.578V70.4h2.028V57.2c0-6.806,3.606-10.006,8.7-10.006,4.913,0,8.2,3.065,8.2,9.014Zm7.617-24.473L56.265,70.4h2.118L68.525,45.929H66.361L57.347,68.194,48.333,45.929ZM74.384,70.4h2.028V45.929H74.384ZM75.42,40.476a1.669,1.669,0,0,0,1.713-1.758,1.713,1.713,0,1,0-3.425,0A1.669,1.669,0,0,0,75.42,40.476ZM98.181,70.4h2.3L92.547,58.1l7.8-12.169H98.226l-6.67,10.547L84.75,45.929h-2.3l7.8,12.124L82.361,70.4h2.163L91.285,59.63Zm58.772,0h1.983V56.07c0-7.346-4.282-10.637-9.51-10.637-4.237,0-7.932,2.163-9.149,6.851-1.217-4.687-4.823-6.851-9.059-6.851-3.876,0-7.256,1.848-8.7,5.814V45.929h-2.028V70.4h2.028V57.377c0-6.851,3.516-10.186,8.383-10.186,4.732,0,7.842,3.065,7.842,9.014V70.4h1.983V57.377c0-6.851,3.561-10.186,8.383-10.186,4.732,0,7.842,3.065,7.842,9.014Zm23.527,0h2.3l-7.932-12.3,7.8-12.169h-2.118l-6.67,10.547-6.806-10.547h-2.3l7.8,12.124L164.66,70.4h2.163l6.761-10.772Z"
                    transform="translate(-8.04 -36.96)"
                    fill="#1e5772"
                  />
                </g>
              </g>
              <g
                id="Grupo_25"
                data-name="Grupo 25"
                transform="translate(7.205 8.646)"
              >
                <g
                  id="Grupo_24"
                  data-name="Grupo 24"
                  transform="translate(14.606 9.107)"
                >
                  <path
                    id="Trazado_14"
                    data-name="Trazado 14"
                    d="M25.664,0h0L43.547,21.466l7.41,23.017L34.951,46.745,5.177,31.456l.206-.054,38.163-9.936-8.6,25.279L25.765,59.314,5.177,31.456,0,15.3"
                    transform="translate(0.121 0)"
                    fill="none"
                    stroke="#4dacb2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Trazado_15"
                    data-name="Trazado 15"
                    d="M-1385.5-891.435l5.3-14.4,20.651-31.356"
                    transform="translate(1385.5 937.202)"
                    fill="none"
                    stroke="#4dacb2"
                    stroke-linejoin="bevel"
                    stroke-width="1"
                  />
                  <path
                    id="Trazado_16"
                    data-name="Trazado 16"
                    d="M-1250.44-893.726l-8.1,6.646"
                    transform="translate(1302.181 908.571)"
                    fill="none"
                    stroke="#4dacb2"
                    stroke-width="1"
                  />
                </g>
                <g
                  id="Polígono_2"
                  data-name="Polígono 2"
                  transform="translate(26.952) rotate(30)"
                  fill="none"
                >
                  <path
                    d="M46.648,0,62.2,26.952,46.648,53.9h-31.1L0,26.952,15.549,0Z"
                    stroke="none"
                  />
                  <path
                    d="M 16.12694549560547 0.9999923706054688 L 1.154487609863281 26.95224380493164 L 16.12693405151367 52.90448379516602 L 46.07056045532227 52.90448379516602 L 61.04298782348633 26.95224380493164 L 46.07056045532227 0.9999923706054688 L 16.12694549560547 0.9999923706054688 M 15.54937744140625 -7.62939453125e-06 L 46.64812850952148 -7.62939453125e-06 L 62.19747924804688 26.95224380493164 L 46.64812850952148 53.90448379516602 L 15.54936599731445 53.90448379516602 L -3.814697265625e-06 26.95224380493164 L 15.54937744140625 -7.62939453125e-06 Z"
                    stroke="none"
                    fill="#4dacb2"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>

        <h1 className="has-text-centered title is-4 is-marginless my-3">
          INVESTIGACIÓN DE ARRENDATARIO
        </h1>
        <div className="divider"></div>
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-6">
              <p className="is-size-5">Arrendatario: </p>
              <b></b>
              <div className="divider">{procedureData.renterName}</div>
            </div>
            <div className="column is-6">
              <p className="is-size-5">Fecha y lugar de nacimiento: </p>
              <b></b>
              <div className="divider">{procedureData.birthDateNPlace}</div>
            </div>
            <div className="column is-6 is-paddingless px-3">
              <p className="is-size-5">CURP: </p>
              <b></b>
              <div className="divider">{procedureData.curp}</div>
            </div>
            <div className="column is-6 is-paddingless px-3">
              <p className="is-size-5">Telefono</p>
              <b></b>
              <div className="divider">{procedureData.phone}</div>
            </div>
            <div className="column is-6 is-paddingless px-3">
              <p className="is-size-5">Nacionalidad: </p>
              <b></b>
              <div className="divider">{procedureData.nationality}</div>
            </div>
            <div className="column is-6 is-paddingless px-3">
              <p className="is-size-5">Estado Civil</p>
              <b></b>
              <div className="divider"> {procedureData.martialStatus} </div>
            </div>
            <div className="column is-6 is-marginless">
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">
                    Actividad Económica
                  </p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="#20555B"
                          d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c10.6 1.6 42.1 6.7 55.1 10c17.1 4.3 27.5 21.7 23.2 38.9s-21.7 27.5-38.9 23.2c-9.3-2.4-37.6-7-48.9-8.7c-32.1-4.8-59.6-2.4-78.5 4.9c-18.3 7-25.9 16.9-27.9 28c-1.9 10.7-.5 16.8 1.3 20.6c1.9 4 5.6 8.5 12.9 13.4c16.2 10.8 41.1 17.9 73.3 26.7l2.8 .8c28.4 7.7 63.2 17.2 89 34.3c14.1 9.4 27.3 22.1 35.5 39.7c8.3 17.8 10.1 37.8 6.3 59.1c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.2-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.4 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.7 .5-16.8-1.3-20.6c-1.9-4-5.6-8.5-12.9-13.4c-16.2-10.8-41.1-17.9-73.3-26.7l-2.8-.8c-28.4-7.7-63.2-17.2-89-34.3c-14.1-9.4-27.3-22.1-35.5-39.7c-8.3-17.8-10.1-37.8-6.3-59.1C25 114.1 53 89.3 86 76.7c13-5 27.2-8.2 42-10V32c0-17.7 14.3-32 32-32z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3 has-background-light">
                  <div className="content">
                    <p className="is-marginless">
                      Fuente de ingresos: <b>{procedureData.mainEntry}</b>
                    </p>
                    <p className="is-marginless">
                      Fuente de Trabajo: <b>{procedureData.jobSource}</b>
                    </p>
                    <p className="is-marginless">
                      Domicilio del Trabajo: <b>{procedureData.jobAddres}</b>
                    </p>
                    <p className="is-marginless">
                      Teléfono del Trabajo: <b>{procedureData.jobPhone}</b>
                    </p>
                    <p className="is-marginless">
                      Ingresos: <b>{procedureData.entry}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-content is-size-6 p-3 has-background-light">
                <p className="card-header-title is-size-5">Otros ingresos</p>
                <div className="content">
                  {procedureData.otherEntry !== "Otros" && (
                    <p className="is-marginless">
                      Fuente de ingresos: <b>{procedureData.otherEntry}</b>
                    </p>
                  )}
                  {procedureData.otherEntry === "Otros" && (
                    <p className="is-marginless">
                      Fuente de ingresos: <b>{procedureData.otherEntryName}</b>
                    </p>
                  )}
                  <p className="is-marginless">
                    Ingresos: <b>{procedureData.otherEntryAmmount}</b>
                  </p>
                </div>
              </div>
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">Fiador</p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#20555B"
                          d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3">
                  <div className="content">
                    <div className="p-2 is-marginless has-background-light">
                      <p className=" is-marginless">
                        Nombre: <b>{procedureData.guarantorName}</b>
                      </p>
                      <p className="is-marginless">
                        CURP: <b>{procedureData.guarantorCurp}</b>
                      </p>
                      <p className="is-marginless">
                        Domicilio: <b>{procedureData.guarantorAddress}</b>
                      </p>
                      <p className="is-marginless">
                        Fecha y lugar de nacimiento:
                        <b>{procedureData.guarantorBirthDateNPlace}</b>
                      </p>
                      <p className="is-marginless">
                        Teléfono del Trabajo:
                        <b> {procedureData.guarantorPhone} </b>
                      </p>
                      <p className="is-marginless">
                        Ocupación: <b> {procedureData.guarantorActivity} </b>
                      </p>
                      <p className="is-marginless">
                        Dirección del inmueble en garantía:
                        <b> {procedureData.guarantorPropertyAddress} </b>
                      </p>
                      {/* <p className="is-marginless">Documentos que presenta: <b> Predial </b></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-6 is-marginless">
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">
                    Destino del Inmueble
                  </p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="#20555B"
                          d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3 has-background-light">
                  <div className="content">
                    <p className="is-marginless">
                      Uso: <b>{procedureData.usage}</b>
                    </p>
                    <p className="is-marginless">
                      Ocupación: <b>{procedureData.persons} personas</b>
                    </p>
                    <p className="is-marginless">
                      Mascotas: <b>{procedureData.hasPets}</b>
                    </p>
                    {procedureData.petType && (
                      <p className="is-marginless">
                        Tipo de Mascota: {procedureData.petType}
                        <b></b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">Historial legal</p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#20555B"
                          d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3 has-background-light">
                  <div className="content">
                    <p className="is-marginless is-flex is-justify-content-space-between">
                      <b>{procedureData?.legalHistoryQualification}</b>
                      {procedureData?.legalHistoryQualification ==
                      "Encontrado (Bueno)" ? (
                        <CheckIcon />
                      ) : (
                        <AlertIcon />
                      )}
                    </p>
                    {/* <p className="is-marginless">Fiador: <b>1 Expediente encontrado</b></p> */}
                  </div>
                </div>
              </div>
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">
                    Historial crediticio
                  </p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="#20555B"
                          d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3 has-background-light">
                  <div className="content">
                    <p className="is-marginless is-flex is-justify-content-space-between">
                      <b>{procedureData?.creditHistoryQualification}</b>
                      {procedureData?.creditHistoryQualification ==
                      "Encontrado (Bueno)" ? (
                        <CheckIcon />
                      ) : (
                        <AlertIcon />
                      )}
                    </p>
                    {/* <p className="is-marginless">Fiador: <b>1 Expediente encontrado</b></p> */}
                  </div>
                </div>
              </div>
              <div className="card is-shadowless">
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">Referencias</p>
                  <div
                    className="button card-header-icon is-white color-primary"
                    aria-label="more options"
                  >
                    <span className="icon has-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="#20555B"
                          d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
                        />
                      </svg>
                    </span>
                  </div>
                </header>
                <div className="card-content is-size-6 p-3 has-background-light">
                  <div className="content">
                    {procedureData.references.map((r) => (
                      <>
                        <div className="is-flex is-align-items-center is-justify-content-space-between">
                          <b key={r._id}>{toTitleCase(r.name)}</b>
                          {r.comments.length != 0 ? (
                            <CheckIcon />
                          ) : (
                            <AlertIcon />
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                <header className="card-header is-shadowless">
                  <p className="card-header-title is-size-5">&nbsp;</p>
                </header>
                <QRCode
                  value={`https://invix.mx/investigacion/${qrToken}`}
                  size="90"
                  style={{ margin: "0 auto" }}
                />
              </div>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-4 is-marginless has-text-center"></div>
          </div>
        </div>
      </main>
    </>
  ) : (
    <div>Cagando...</div>
  );
};

export default GenPdf;
