import React from 'react'
import { SignUpForm } from '../../components/SignUpForm'
import { Padding } from '../../components/Padding';

export const SignUp = (props) => {
  return (
    <Padding padding='100px' className="login">
      <div className="notification is-white">
        <SignUpForm/>
      </div>
    </Padding>
  )
}
