import React from 'react'

export const Input = (props) => {
  const {
    label,
    type = 'text',
    placeholder='',
    onChange,
    error,
    showError,
    value,
    helper,
    isLoading,
    inputClass,
    iconLeft,
    ...rest
  } = props;

  return (
    <div className="field">
      <label className="label">{ label }</label>
      <div className={`control ${isLoading ? 'is-loading' : ''} ${iconLeft != null ? 'has-icons-left' : ''}`}>        
        {iconLeft}
        <input className={`input ${inputClass}`} type={type} placeholder={placeholder} onChange={onChange} value={value} {...rest}/>
      </div>
      {
        helper
      }
      {
        showError && <p className="help is-danger">{ error }</p>
      }
    </div>
  )
}
