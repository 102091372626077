import React, { useContext, useState, useEffect } from "react";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Padding } from "../../components/Padding";
import { Panel } from "../../components/Panel";
import {
  RenterContext,
  RenterInformationContext,
} from "../../services/Renter/context";
import { GeneralInformation } from "../RenterPanel/Forms/GeneralInformation";
import {
  DisableNotification,
  GetNotificationConfig,
  NotificationNames,
} from "../../services/NotificationService";
import { EconomyActivity } from "../RenterPanel/Forms/EconomyActivity";
import { DestinationProperty } from "../RenterPanel/Forms/DestinationProperty";
import { PersonalReferences } from "../RenterPanel/Forms/PersonalReferences";
import { GuarantorData } from "../RenterPanel/Forms/GuarantorData";
import { GetIssues, GetProcedure } from "../../services/Renter";
import Issues from "../../components/Issues";
import { useQuery } from "../../hooks/useQuery";

export const RenterInformation = (props) => {
  const query = useQuery();
  const { renterData: data, informationCompleted } = useContext(RenterContext);
  const [procedureStatus, setProcedureStatus] = useState(0);
  const [renterData, setRenterData] = useState(data);
  const [hideNotification, setHideNotification] = useState(
    GetNotificationConfig(NotificationNames.informationCompleted)
  );

  const [issues, setIssues] = useState([]);

  const completetSection = (section, sectionData) => {
    if (section === "personalReferences") {
      setRenterData({
        ...renterData,
        [section]: sectionData,
      });
      return;
    }

    setRenterData({
      ...renterData,
      [section]: {
        ...sectionData,
        completed: true,
      },
    });
  };
  useEffect(() => {
    const loadIssues = async () => {
      let {
        data: { issues: issuesFinded = [] },
      } = await GetIssues();
      setIssues(issuesFinded);
    };

    loadIssues();
  }, []);

  const updateReferenceData = (reference, index) => {
    let newRefs = renterData.personalReferences;
    newRefs[index] = reference;
    setRenterData(() => ({ ...renterData, personalReferences: newRefs }));
  };

  const getProcedureStatus = async () => {
    const procedureId = query.get("procedure");
    if (procedureId) {
      const { data } = await GetProcedure(procedureId);
      setProcedureStatus(data.status ?? 0);
    }
  };

  useEffect(() => {
    getProcedureStatus();
  }, []);

  const procedureId = query.get("procedure");
  const procedureParam = procedureId != null ? `?procedure=${procedureId}` : "";
  const fieldsWithIssues = issues.map((i) => {
    return {
      name: i.field,
      description: i.description,
    };
  });
  const isFieldWithIssues = (fieldName) => {
    return procedureStatus != 0 && !fieldsWithIssues.some((field) => field.name == fieldName);
  };

  return (
    <Padding padding="20px 50px" mobilePadding="0px">
      {issues.length !== 0 ? (
        <Panel>
          <p className="title">Problemas con la información</p>
          <p className="subtitle">
            Revisa los mensajes a continuación para resolver los problemas o
            completar la información indicada, una vez actualizada nuestros
            agentes la revisarán de nuevo.
          </p>
          <hr className="is-marginless" />
          <Issues issues={issues} />
        </Panel>
      ) : null}
      <br />
      <RenterInformationContext.Provider
        value={{
          completetSection,
          procedureStatus,
          getProcedureStatus,
          isFieldWithIssues
        }}
      >
        <Panel>
          <p className="title">Información</p>
          <br />
          <br />
          <hr className="is-marginless" />
          <div className="tabs is-toggle is-fullwidth">
            <ul>
              <li>
                <NavLink
                  to={`/panel/informacion/general${procedureParam}`}
                  activeClassName="is-active"
                >
                  <span className="icon">
                    <i
                      className={`icon ${
                        renterData.generalData.completed
                          ? "fas has-text-success"
                          : "far"
                      } fa-check-circle fa-lg`}
                    ></i>
                  </span>
                  <span>Información General</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/panel/informacion/actividad-economica${procedureParam}`}
                  activeClassName="is-active"
                >
                  <span className="icon">
                    <i
                      className={`icon ${
                        renterData.economyActivity.completed
                          ? "fas has-text-success"
                          : "far"
                      } fa-check-circle fa-lg`}
                    ></i>
                  </span>
                  <span>Actividad Económica</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/panel/informacion/destino${procedureParam}`}
                  activeClassName="is-active"
                >
                  <span className="icon">
                    <i
                      className={`icon ${
                        renterData.destinationProperty.completed
                          ? "fas has-text-success"
                          : "far"
                      } fa-check-circle fa-lg`}
                    ></i>
                  </span>
                  <span>Destino del Inmueble</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/panel/informacion/referencias${procedureParam}`}
                  activeClassName="is-active"
                >
                  <span className="icon">
                    <i
                      className={`icon ${
                        renterData.personalReferences.length >= 2
                          ? "fas has-text-success"
                          : "far"
                      } fa-check-circle fa-lg`}
                    ></i>
                  </span>
                  <span>Referencias</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/panel/informacion/fiador${procedureParam}`}
                  activeClassName="is-active"
                >
                  <span className="icon">
                    <i
                      className={`icon ${
                        renterData.guarantorData.completed ||
                        renterData.guarantorProperty.completed
                          ? "fas has-text-success"
                          : "far"
                      } fa-check-circle fa-lg`}
                    ></i>
                  </span>
                  <span>Datos del Fiador</span>
                </NavLink>
              </li>
            </ul>
          </div>

          {informationCompleted(renterData) || !hideNotification ? null : (
            <div className="notification is-warning">
              <b>Aún tienes información incompleta</b>, al terminar podrás
              continuar con tu proceso.
              <br />
              <br />
              <div className="buttons">
                <button
                  type="button"
                  className="button is-dark is-outlined"
                  onClick={() => setHideNotification(false)}
                >
                  Ocultar por ahora
                </button>
                <button
                  type="button"
                  className="button is-danger is-light"
                  onClick={() => {
                    DisableNotification(NotificationNames.informationCompleted);
                    setHideNotification(false);
                  }}
                >
                  No volver a mostrar
                </button>
              </div>
            </div>
          )}

          <Switch>
            <Redirect
              exact
              from="/panel/informacion"
              to={`/panel/informacion/general${procedureParam}`}
            />
            <Route
              path="/panel/informacion/general"
              render={(props) => (
                <GeneralInformation
                  generalData={renterData.generalData}
                  {...props}
                />
              )}
            />
            <Route
              path="/panel/informacion/actividad-economica"
              render={() => (
                <EconomyActivity
                  economyActivity={renterData.economyActivity}
                  completetSection={completetSection}
                  {...props}
                />
              )}
            />
            <Route
              path="/panel/informacion/destino"
              render={() => (
                <DestinationProperty
                  destinationProperty={renterData.destinationProperty}
                  completetSection={completetSection}
                  {...props}
                />
              )}
            />
            <Route
              path="/panel/informacion/referencias"
              render={() => (
                <PersonalReferences
                  personalReferences={renterData.personalReferences}
                  completetSection={completetSection}
                  {...props}
                  updateReferenceData={updateReferenceData}
                />
              )}
            />
            <Route
              path="/panel/informacion/fiador"
              render={() => (
                <GuarantorData
                  guarantorData={renterData.guarantorData}
                  guarantorProperty={renterData.guarantorProperty}
                  completetSection={completetSection}
                  {...props}
                />
              )}
            />
          </Switch>
        </Panel>
      </RenterInformationContext.Provider>
    </Padding>
  );
};
