import { useState } from "react";
import NumberFormat from "react-number-format";

const OtherEntryLabels = {
  ingreso_de_la_pareja: "Ingreso de la pareja",
  negocio: "Negocio",
  empleo: "Empleo",
  otros: "Otros",
};

export const EconomyActivity = ({ economyActivity, creditHistory, renter, openFile }) => {
  const [open, setOpen] = useState(false);
  const RenderMainEntry = () => {
    /**
     * Empleo -> workSource
     * Negocio -> businessDesc
     * Pensión -> pensionSource
     * Otro -> other
     */
    switch (economyActivity?.mainEntry) {
      case "empleo":
        return economyActivity?.workSource;
      case "negocio":
        return economyActivity?.businessDesc;
      case "pension":
        return economyActivity?.pensionSource;
      case "otro":
        return economyActivity?.other;
      default:
        return null;
    }
  };

  return (
    <div className="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Actividad Económica
      </p>
      {open && (
        <div>
          {economyActivity?.notes && (
            <div className="notification">
              Notas de nuestro agente: <br />
              <b>{economyActivity?.notes}</b>
            </div>
          )}

          <p className="has-text-weight-bold is-size-5">
            Documentación proporcionada:
          </p>
          <div className="buttons">
            {economyActivity.entryFile.map((f, i) => <button
                key={i}
                className="button is-light is-primary"
                onClick={() => openFile([f])}
              >
                Comprobante de ingreso {i + 1}
              </button>)}
            {economyActivity.otherFile?.map((f, i) => <button
                key={i}
                className="button is-light is-primary"
                onClick={() => openFile([f])}
              >
                Comprobante de ingreso extra {i + 1}
              </button>)}
          </div>
          <div className="buttons">
            {creditHistory.map((f, i) => <button
                key={i}
                className="button is-light is-primary"
                onClick={() => openFile([f])}
              >
                Historial crediticio
              </button>)}
          </div>
          <p className="has-text-weight-bold is-size-5">
            Documentación revisada:
          </p>
          <div className="buttons">
            {economyActivity.invoiceValidations.map((f, i) => (
              <button
                key={i}
                className="button is-light is-primary"
                onClick={() => openFile([f])}
              >
                Validación de ingreso { i +1}
              </button>
            ))}
          </div>

          <p className="my-1 is-size-5">
            Fuente principal de ingreso:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.mainEntry}
            </b>
          </p>

          <p className="my-1 is-size-5">
            Otros ingresos:
            <b className="has-text-primary is-uppercase ml-2">
              {OtherEntryLabels[economyActivity.otherEntry]}
            </b>
          </p>

          <p className="my-1 is-size-5">
            Fuente de empleo: &nbsp;
            <b className="has-text-primary is-uppercase ml-2">
              <RenderMainEntry />
            </b>
          </p>

          <p className="my-1 is-size-5">
            Fuente de trabajo:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.workSource}
            </b>
          </p>

          <p className="my-1 is-size-5">
            Tiempo en el trabajo:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.timeInJob}
            </b>
          </p>

          <p className="my-1 is-size-5">
            Número del trabajo:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.jobPhone}
            </b>
          </p>
          <hr />
          <p className="my-1 is-size-5 has-text-primary is-uppercase ml-2 is-uppercase ml-2 has-text-weight-bold">
            Llamadas al trabajo:
          </p>
          {economyActivity.callHistory?.map((call, i) => (
            <div>
              <p className="my-1 is-size-5 is-uppercase ml-2 has-text-weight-bold	">
                Llamada {i + 1}
              </p>
              <p className="my-1 is-size-5">
                ¿Contestó?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.answered ? "Si" : "NO"}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Conoce al referido?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.known}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Tempo de conocerle
                <b className="has-text-primary is-uppercase ml-2">
                  {call.meetTime}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Cuál es su relación con el referido?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.relationship}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Lo considera solvente económicamente?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.financialSolvency}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Lo considera una persona responsable?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.isResponsible}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Lo recomienda como inquilino?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.recomended}
                </b>
              </p>
              <p className="my-1 is-size-5">
                ¿Sabe cuál es su activad laboral?
                <b className="has-text-primary is-uppercase ml-2">
                  {call.jobActivity}
                </b>
              </p>
              <hr />
            </div>
          ))}

          <p className="my-1 is-size-5">
            Sitio web de la empresa:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.website}
            </b>
          </p>

          <p className="my-1 is-size-5">
            Dirección del trabajo:
            <b className="has-text-primary is-uppercase ml-2">
              {economyActivity.address}
            </b>
          </p>
          <hr />
          <p className="my-1 is-size-5 has-text-primary is-uppercase ml-2 is-uppercase ml-2 has-text-weight-bold">
            Ingresos
          </p>
          <div className="is-flex is-align-items-baseline is-flex-wrap-wrap">
            <div
              className="notification is-light mr-3 is-full-mobile"
              style={{ width: "300px", height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-primary is-uppercase ml-2 is-flex">
                Ingreso Mensual
              </span>
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.mensualEntry}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
            <div
              className="notification is-light mr-3 is-full-mobile"
              style={{ width: "300px", height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-primary is-uppercase ml-2 is-flex">
                Otros ingresos
              </span>
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.otherEntryAmmount ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>

            <div
              className="notification is-light mr-3 is-full-mobile"
              style={{ height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-primary is-uppercase ml-2">
                Total ingresos
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={
                    economyActivity?.mensualEntry +
                    (economyActivity?.otherEntryAmmount ?? 0)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>

            <div
              className="notification is-light mr-3 is-full-mobile"
              style={{ height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-danger">
                Gastos
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.totalMonthExpenses}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
            <div
              className="notification is-light is-full-mobile"
              style={{ height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-primary is-uppercase ml-2">
                Total Libre
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={
                    (economyActivity?.otherEntryAmmount ?? 0) +
                    economyActivity?.mensualEntry -
                    economyActivity?.totalMonthExpenses
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
          </div>
          <br />
          <div className="is-flex is-align-items-baseline is-flex-wrap-wrap">
            <div
              className="notification is-primary has-text-white mr-2 is-full-mobile"
              style={{ width: "280px", height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-white">
                Ingreso Mensual Compobrado
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.mensualEntryComparation}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
            <div
              className="notification is-primary has-text-white mr-3 is-full-mobile"
              style={{ width: "300px", height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-white">
                Otros ingresos Compobrados
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.otherEntryComparation ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>

            <div
              className="notification is-primary has-text-white mr-3 is-full-mobile"
              style={{ height: "150px", width: "200px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-white">
                Total ingresos Compobrados
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={
                    parseInt(economyActivity?.mensualEntryComparation) +
                    parseInt(economyActivity?.otherEntryComparation ?? 0)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>

            <div
              className="notification is-primary has-text-white mr-3 is-full-mobile"
              style={{ height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-danger">
                Gastos
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={economyActivity?.totalMonthExpenses}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
            <div
              className="notification is-primary has-text-white"
              style={{ height: "150px" }}
            >
              <span className="is-uppercase has-text-weight-bold has-text-white">
                Total Disponible
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                <NumberFormat
                  value={
                    parseInt(economyActivity?.otherEntryComparation) +
                    parseInt(economyActivity?.mensualEntryComparation) -
                    parseInt(economyActivity?.totalMonthExpenses)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};
