import React, { useState } from "react";
const usages = [
  "Casa habitación",
  "Comercial",
  "Oficina",
  "Industrial",
  "Bodega",
  "Agricola",
  "Mixto",
  "Otro",
];
export const DestinationProperty = ({
  destinationProperty,
  renter,
  openFile,
}) => {
  const { isBusiness } = renter;
  const [open, setOpen] = useState(false);
  return (
    <div className="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Destino del inmueble
      </p>
      {open && (
        <div>
          <div className="notification is-light mr-3">
            <span className="is-uppercase has-text-weight-bold has-text-primary">
              Uso
            </span>
            <br />
            <span className="has-text-weight-bold is-size-5">
              {usages[destinationProperty?.usage]}
            </span>
          </div>
          {destinationProperty?.usage === 0 && isBusiness ? (
            <p className="my-1 is-size-5">
              ...
              <b className="has-text-primary">
                {destinationProperty.habitationalName}
              </b>
            </p>
          ) : null}
          <p className="has-text-weight-bold">Personas que habitarán/usarán</p>
          <br />
          <div className="is-flex is-align-items-baseline is-flex-wrap-wrap">
            {destinationProperty?.childrenAges?.map((children, i) => (
              <div className="notification is-light mr-3 is-full-mobile">
                <span className="is-uppercase has-text-weight-bold has-text-primary">
                  Niño {i + 1}
                </span>
                <br />
                <span className="has-text-weight-bold is-size-2">
                  {children?.age}
                </span>{" "}
                &nbsp; años
              </div>
            ))}
            <div className="notification is-light mr-3 is-full-mobile">
              <span className="is-uppercase has-text-weight-bold has-text-primary">
                Adultos
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                {destinationProperty?.adults}
              </span>
            </div>
            <div className="notification is-light mr-3 is-full-mobile">
              <span className="is-uppercase has-text-weight-bold has-text-primary">
                Total
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                {destinationProperty?.totalPeople}
              </span>
            </div>
          </div>
          <p className="has-text-weight-bold">Mascotas</p>
          <br />
          {destinationProperty?.hasPets ? (
            <div className="notification is-light mr-3">
              <span className="has-text-weight-bold is-size-5">
                {destinationProperty?.petType}
              </span>
            </div>
          ) : (
            <div className="notification is-light mr-3">
              <span className="has-text-weight-bold is-size-5">
                No habrá mascotas
              </span>
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
