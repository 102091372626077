import React, { useContext, useState, useEffect } from 'react'
import { Input } from '../../../../components/Form'
import { useQuery } from '../../../../hooks/useQuery';
import { Models, UpdateField, GetSection } from '../../../../services/Renter'
import { RenterContext, RenterInformationContext } from '../../../../services/Renter/context';
import { isObjectFilled } from '../../../../Utils';
let Usages = {
  other : -1,
  habitational : 0,
  comercial : 1,
  office : 2,
  industrial : 3,
  cellar : 4,
  agricultural : 5,
  mix : 6,
}
const initialData = {
  usage: '1',
  totalPeople: 0,
  children: 0,
  adults: 0,
  olderAdults: 0,
  hasPets: false,
  petType: '',
  habitationalName: '',
  childrenAges: []
}

export const DestinationProperty = (props) => {
  const query = useQuery();
  const procedure = query.get('procedure')
  const { destinationProperty: defaultData } = props;
  const { completetSection, isFieldWithIssues } = useContext(RenterInformationContext);


  const [completedSection] = useState(defaultData.completed)
  const { renterData: { isBusiness }, procedureData } = useContext(RenterContext)
  
  const disabled = procedureData?.status == 3;
  
  const [destinationProperty, setDestinationPoperty] = useState({...initialData, ...defaultData})

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState('');

  const [childrenAges, setChildrenAges] = useState(defaultData.childrenAges ?? [])

  useEffect(() => {
    async function getData() {
      let { data } = await GetSection({
        section: Models.destinationProperty
      })
      setChildrenAges(data.childrenAges ?? [])
      setDestinationPoperty(() => data);
    }
    getData();
  }, [])

  const addChildren = () => {
    setChildrenAges([...childrenAges, {
      age: 0
    }]);
  }

  const removeChildren = () => {
    childrenAges.pop();
    setChildrenAges([...childrenAges]);
    saveChildren()
  }

  const updateChildAge = (index, value) => {
    childrenAges[index].age = value;
    setChildrenAges([...childrenAges]);
  }

  const saveChildren = async () => {
    updateField('childrenAges', childrenAges);
  }
 
  const updateField = async (field, value) => {
    setLoading(field)

    let {
      children,
      olderAdults,
      usage,
      habitationalName,
      adults,
      petType,
      ...rest
    } = destinationProperty;
    let completed = isObjectFilled(rest);

    if(!completedSection && completed) {
      completetSection('destinationProperty', destinationProperty);
    }

    await UpdateField({
      modelName: Models.destinationProperty,
      update: {
        [field]: value,
        completed,
      }
    })

    setLoading('')
  }

  const next = async () => {
    setShowErrors(true);
    let { 
      usage,
      habitationalName,
      olderAdults,
      adults,
      children,
      petType,
      ...rest
    } = destinationProperty;
    let completed = isObjectFilled(rest);
    
    if(completed) {
      await UpdateField({
        modelName: Models.destinationProperty,
        update: {
          completed,
        }
      })
      completetSection('destinationProperty', {destinationProperty, ...childrenAges});
      const procedureParam = procedure ? `?procedure=${procedure._id}` : '';
      return props.history.push(`/panel/informacion/referencias${procedureParam}`);
    }

    setShowErrors(true);
  }

  return (
    <div className="info-form">
      <div className="navbar" >
        <div className="navbar-start">
          <div>
            <p className="title is-4 has-text-primary">Destino del inmueble</p>
            <p className="subtitle is-5">Los datos se guardan automáticamente</p>
          </div>
        </div>
        <div className="navbar-end">
          <button className="button is-primary is-fullwidth-mobile" onClick={next}>
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>   
      <hr/>

      <div className="columns is-multiline">
        <div className="column is-12">
          <p className="is-size-5 has-text-primary has-text-weight-bold	">
            Uso pretendido del inmueble: 
          </p>
          <br/>
          <div className="field">    
            <div className="control" onChange={({ target: { value } }) => {
              setDestinationPoperty({...destinationProperty, usage: parseInt(value)});
              updateField('usage', parseInt(value));
            }}>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.habitational} type="radio" checked={destinationProperty.usage == Usages.habitational} name="usage" readOnly/>
                Casa habitación
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.comercial} type="radio" checked={ destinationProperty.usage == Usages.comercial } name="usage" readOnly/>
                Comercial
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.office} type="radio" checked={ destinationProperty.usage == Usages.office } name="usage" readOnly/>
                Oficina
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.industrial} type="radio" checked={ destinationProperty.usage == Usages.industrial } name="usage"  readOnly/>
                Industrial
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.cellar} type="radio" checked={ destinationProperty.usage == Usages.cellar } name="usage" readOnly />
                Bodega
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={ Usages.agricultural } type="radio" checked={ destinationProperty.usage == Usages.agricultural } name="usage" readOnly />
                Agricola
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.mix} type="radio" checked={destinationProperty.usage == Usages.mix } name="usage" readOnly />
                Mixto
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('usage')} value={Usages.other} type="radio" checked={destinationProperty.usage == Usages.other } name="usage" readOnly />
                Otro
              </label>
            </div>
          </div>            
        </div>
        {
          destinationProperty.usage === Usages.habitational && isBusiness &&  (
           <div className="column is-12">
              <Input
                disabled={isFieldWithIssues('habitationalName')}
                isLoading={loading === 'habitationalName'}
                label="Nombre de quien habilitara el Inmueble"
                type="text"
                placeholder=''
                onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, habitationalName: value}) }
                value={destinationProperty.habitationalName}
                onBlur={(e) => updateField('habitationalName', destinationProperty.habitationalName)}
              />
           </div>
          )
        }
        <div className="column is-4">
          {
            destinationProperty.usage === Usages.other && <Input
              disabled={isFieldWithIssues('other')}
              isLoading={loading === 'other'}
              label="Especifique"
              type="text"
              placeholder=''
              onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, other: value}) }
              value={destinationProperty.other}
              error="Este campo es obligatorio"
              showError={destinationProperty.other === "" && showErrors}
              onBlur={(e) => updateField('other', destinationProperty.other)}
            />
          }
        </div>
        <div className="column is-8"></div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues('totalPeople')}
            isLoading={loading === 'totalPeople'}
            label=" Personas que habitarán/usarán el inmueble"
            type="number"
            placeholder='Total de personas'
            onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, totalPeople: value}) }
            value={destinationProperty.totalPeople}
            error="Este campo es obligatorio"
            showError={destinationProperty.totalPeople === "" && showErrors}
            onBlur={(e) => updateField('totalPeople', destinationProperty.totalPeople)}
          />
        </div>        
        <div className="column is-2">
          <Input
            isLoading={loading === 'adults'}
            label="Cantidad de adultos"
            type="number"
            placeholder=''
            disabled={isFieldWithIssues('adults')}  
            onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, adults: value}) }
            value={destinationProperty.adults}
            onBlur={(e) => updateField('adults', destinationProperty.adults)}
          />
        </div>
        {
          destinationProperty.usage === 0 && <div className="column is-4"> 
          <label className="label">Cantidad de niños</label>
          <div className="buttons">
            <button disabled={isFieldWithIssues('usage')} className="button is-light is-info" onClick={() => addChildren()}>
              Agregar niño
            </button>  
            <button className="button is-light is-danger" onClick={() => removeChildren()}>
              Quitar niño
            </button>  
          </div>
        </div>    
        }             
        <div className="column is-12">          
          <div className="columns is-multiline">
          {
            childrenAges.map((ca, index) => (
             <div className="column is-2">
                <Input
                  disabled={disabled}
                  label={`Edad del niño ${index + 1}`}
                  type="number"
                  placeholder=''
                  onChange={({ target: { value } }) => updateChildAge(index, value) }
                  value={ca.age}
                  onBlur={() => saveChildren()}
                />                  
             </div> 
            ))
          }
          </div>
        </div>
       {/*  <div className="column is-4">
          <Input
            disabled={disabled}
            isLoading={loading === 'olderAdults'}
            label="Adultos mayores"
            type="number"
            placeholder=''
            onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, olderAdults: value}) }
            value={destinationProperty.olderAdults}
            onBlur={(e) => updateField('olderAdults', destinationProperty.olderAdults)}
          />
        </div> */}
        <div className="column is-4">
          <p className="is-size-5 has-text-primary has-text-weight-bold	">
            ¿Tiene mascotas?
          </p>
          <br/>
          <div className="field">
            <div className="control" value={destinationProperty.hasPets} onChange={({ target: { value } }) => {
              setDestinationPoperty({...destinationProperty, hasPets: JSON.parse(value)});
              updateField('hasPets', JSON.parse(value));
            }}>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('hasPets')} type="radio" value={true} defaultChecked={destinationProperty.hasPets} name="hasPets" />
                Si
              </label>
              <label className="radio is-size-5" style={{marginRight: '30px'}}>
                <input disabled={isFieldWithIssues('hasPets')} type="radio" value={false} defaultChecked={destinationProperty.hasPets === false} name="hasPets" />
                No
              </label>
            </div>
          </div>        
        </div>
        <div className="column is-4">
          {
            destinationProperty.hasPets && <Input
              disabled={isFieldWithIssues('petType')}
              isLoading={loading === 'petType'}
              label="Tipo de mascota"
              type="text"
              placeholder='Especie y/o Raza y Cantidad'
              onChange={({ target: { value } })=> setDestinationPoperty({...destinationProperty, petType: value}) }
              value={destinationProperty.petType}
              error="Este campo es obligatorio"
              showError={destinationProperty.petType === "" && showErrors}
              onBlur={(e) => updateField('petType', destinationProperty.petType)}
            />
          }
        </div>
      </div>
      <div className="navbar">
        <div className="navbar-start">
        </div>
        <div className="navbar-end">
          <button className="button is-primary is-fullwidth-mobile" onClick={next}>
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>  
    </div>
  )
}
