import React, { useContext, useState, useEffect } from "react";
import { CreditHistoryAuth } from "../../../../components/CreditHistoryAuth";
import { Input } from "../../../../components/Form";
import {
  GetSection,
  Models,
  UpdateField,
  UploadFile,
} from "../../../../services/Renter";
import {
  RenterContext,
  RenterInformationContext,
} from "../../../../services/Renter/context";
import { isObjectFilled } from "../../../../Utils";

const initialData = {
  /**
   * Empleo -> workSource
   * Negocio -> businessDesc
   * Pensión -> pensionSource
   * Otro -> other
   */
  mainEntry: "",
  workSource: "",
  businessDesc: "",
  pensionSource: "",
  other: "",
  timeInJob: "",

  /**
   * Disable on Pensión y Otro
   */
  address: "",

  /**
   * Disable on Pensión y Otro
   */
  jobPhone: "",
  website: "",
  mensualEntry: 0,
  /**
   * Con opciones de check ( se pueden elegir varias)
   * ( ) Ingreso de la pareja
   * ( X ) Negocio
   * ( ) Empleo
   * ( ) Otros / especifique
   */
  otherEntry: [""],

  // Habilitar si otherEntry es 'otros'
  otherEntryName: "",
  otherEntryAmmount: 0,
  totalMonthExpenses: 0,
  mainActivity: "",
  checkCreditHistorial: false,
};

export const EconomyActivity = (props) => {
  const { economyActivity: defaultData } = props;
  const { completetSection, isFieldWithIssues } = useContext(RenterInformationContext);

  const {
    renterData: { isBusiness },
    disabled,
  } = useContext(RenterContext);

  const [economyActivity, setEconomyActivity] = useState({
    ...initialData,
    ...defaultData,
  });

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [files, setFiles] = useState({
    entry: economyActivity.entryFile || [],
    other: economyActivity.otherFile || [],
  });

  useEffect(() => {
    async function getData() {
      let { data } = await GetSection({
        section: Models.economyActivity,
      });
      setFiles({
        entry: data.entryFile || [],
        other: data.otherFile || [],
      });
      setEconomyActivity(data);
    }
    getData();
  }, []);

  const updateField = async (field, value) => {
    setLoading(field);

    let {
      workSource,
      businessDesc,
      pensionSource,
      other,
      website,
      otherEntryName,
      otherEntryAmmount,
      mainActivity,
      totalMonthEntry,
      otherEntryComparation,
      ...rest
    } = economyActivity;
    let completed = isObjectFilled(rest);

    await UpdateField({
      modelName: Models.economyActivity,
      update: {
        [field]: value,
        completed,
      },
    });

    setLoading("");
  };

  const targetFile = (file, id) => {
    document.getElementById(file).click();
  };

  const setFile = async (e) => {
    let filesSelected = e.target.files;
    let data = new FormData();
    for (let i = 0; i < filesSelected.length; i++) {
      data.append(filesSelected[i].name, filesSelected[i]);
    }
    data.append("fileName", e.target.id);
    data.append("modelName", Models.economyActivity);
    let {
      data: { urls },
    } = await UploadFile(data);
    setFiles({ ...files, [e.target.id]: [...urls] });
  };

  const deleteFile = (file, indexFile) => {
    let filesUpdate = files[file];
    filesUpdate.splice(indexFile, 1);
    setFiles({ ...files, [file]: [...filesUpdate] });
  };

  const next = async () => {
    if (!economyActivity.checkCreditHistorial) {
      setShowErrors(true);
      return;
    }

    let {
      workSource,
      businessDesc,
      pensionSource,
      mainEntry,
      other,
      website,
      otherEntryName,
      otherEntryAmmount,
      mainActivity,
      totalMonthEntry,
      otherEntryComparation,
      ...rest
    } = economyActivity;
    let completed = isObjectFilled(rest);

    if (completed) {
      await UpdateField({
        modelName: Models.economyActivity,
        update: {
          completed,
        },
      });
      completetSection("economyActivity", economyActivity);
      return props.history.push("/panel/informacion/destino");
    }

    setShowErrors(true);
  };

  const addOtherEntry = ({ target: { checked, id } }) => {
    let otherEntry = economyActivity.otherEntry;
    let idx = otherEntry.indexOf(id);
    idx === -1 ? otherEntry.push(id) : otherEntry.splice(idx, 1);
    setEconomyActivity({ ...economyActivity, otherEntry: otherEntry });
    updateField("otherEntry", economyActivity.otherEntry);
  };
  return (
    <div className="info-form">
      <div className="navbar">
        <div className="navbar-start">
          <div>
            <p className="title is-4 has-text-primary">Actividad Económica</p>
            <p className="subtitle is-5">
              Los datos se guardan automáticamente
            </p>
          </div>
        </div>
        <div className="navbar-end">
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={next}
          >
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
      <hr />
      <div className="columns is-multiline">
        {isBusiness && (
          <>
            <div className="column is-12">
              <Input
                disabled={isFieldWithIssues('mainActivity')}
                label="Actividad principal (De la empresa)"
                type="text"
                error="Campo obligatorio"
                showError={economyActivity.mainActivity === "" && showErrors}
                placeholder="Actividad de la empresa"
                value={economyActivity.mainActivity}
                onChange={({ target: { value } }) =>
                  setEconomyActivity({
                    ...economyActivity,
                    mainActivity: value,
                  })
                }
                onBlur={(e) =>
                  updateField("mainActivity", economyActivity.mainActivity)
                }
              />
            </div>
          </>
        )}
        {!isBusiness && (
          <>
            <div className="column is-3">
              <div className="field">
                <label className="label">Fuente principal de ingreso</label>
                <div className="control">
                  <div
                    className={`select is-fullwidth ${
                      loading === "mainEntry" ? "is-loading" : ""
                    }`}
                  >
                    <select
                      disabled={isFieldWithIssues('mainEntry')}
                      value={economyActivity.mainEntry}
                      onChange={({ target: { value } }) => {
                        setEconomyActivity({
                          ...economyActivity,
                          mainEntry: value,
                        });
                        updateField("mainEntry", value);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      <option value="empleo">Empleo</option>
                      <option value="negocio">Negocio</option>
                      <option value="pension">Pensión</option>
                      <option value="otro">Otro</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3">
              {economyActivity.mainEntry === "empleo" && (
                <Input
                  disabled={isFieldWithIssues('workSource')}
                  label="Fuente de empleo"
                  type="text"
                  error="Campo obligatorio"
                  showError={economyActivity.workSource === "" && showErrors}
                  placeholder="Nombre de la empresa o negocio"
                  value={economyActivity.workSource}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({
                      ...economyActivity,
                      workSource: value,
                    })
                  }
                  onBlur={(e) =>
                    updateField("workSource", economyActivity.workSource)
                  }
                />
              )}

              {economyActivity.mainEntry === "negocio" && (
                <Input
                  disabled={isFieldWithIssues('businessDesc')}
                  label="Descripción del negocio"
                  type="text"
                  error="Campo obligatorio"
                  showError={economyActivity.businessDesc === "" && showErrors}
                  placeholder="Descripción del negocio"
                  value={economyActivity.businessDesc}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({
                      ...economyActivity,
                      businessDesc: value,
                    })
                  }
                  onBlur={(e) =>
                    updateField("businessDesc", economyActivity.businessDesc)
                  }
                />
              )}

              {economyActivity.mainEntry === "pension" && (
                <Input
                  disabled={isFieldWithIssues('pensionSource')}
                  label="Fuente de pensión"
                  type="text"
                  error="Campo obligatorio"
                  showError={economyActivity.pensionSource === "" && showErrors}
                  placeholder="Fuente de pensión"
                  value={economyActivity.pensionSource}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({
                      ...economyActivity,
                      pensionSource: value,
                    })
                  }
                  onBlur={(e) =>
                    updateField("pensionSource", economyActivity.pensionSource)
                  }
                />
              )}

              {economyActivity.mainEntry === "otro" && (
                <Input
                  disabled={isFieldWithIssues('other')}
                  label="Especifique"
                  type="text"
                  error="Campo obligatorio"
                  showError={economyActivity.address === "" && showErrors}
                  placeholder="Especifique"
                  value={economyActivity.other}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({ ...economyActivity, other: value })
                  }
                  onBlur={(e) => updateField("other", economyActivity.other)}
                />
              )}
            </div>
            <div className="column is-3">
              <Input
                disabled={isFieldWithIssues('timeInJob')}
                label="Antigüedad"
                type="text"
                error="Campo obligatorio"
                showError={economyActivity.timeInJob === "" && showErrors}
                placeholder="Ej. 1 año 2 meses"
                value={economyActivity.timeInJob}
                onChange={({ target: { value } }) =>
                  setEconomyActivity({ ...economyActivity, timeInJob: value })
                }
                onBlur={(e) =>
                  updateField("timeInJob", economyActivity.timeInJob)
                }
              />
            </div>
            <div className="column is-4">
              {["empleo", "negocio"].indexOf(economyActivity.mainEntry) !==
                -1 && (
                <Input
                  disabled={isFieldWithIssues('address')}
                  label="Domicilio del trabajo o negocio"
                  type="text"
                  placeholder="Calle número colonia municpio estado CP"
                  error="Campo obligatorio"
                  showError={economyActivity.address === "" && showErrors}
                  value={economyActivity.address}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({ ...economyActivity, address: value })
                  }
                  onBlur={(e) =>
                    updateField("address", economyActivity.address)
                  }
                />
              )}
            </div>
            <div className="column is-4">
              {["empleo", "negocio"].indexOf(economyActivity.mainEntry) !==
                -1 && (
                <Input
                  disabled={isFieldWithIssues('jobPhone')}
                  label="Teléfono del trabajo o negocio"
                  type="tel"
                  maxLength="10"
                  error="Campo obligatorio"
                  showError={economyActivity.jobPhone === "" && showErrors}
                  placeholder="10 dígitos"
                  value={economyActivity.jobPhone}
                  onChange={({ target: { value } }) =>
                    setEconomyActivity({ ...economyActivity, jobPhone: value })
                  }
                  onBlur={(e) =>
                    updateField("jobPhone", economyActivity.jobPhone)
                  }
                />
              )}
            </div>
          </>
        )}
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues('website')}
            label="Sito web (opcional)"
            type="text"
            placeholder="O redes sociales"
            value={economyActivity.website}
            onChange={({ target: { value } }) =>
              setEconomyActivity({ ...economyActivity, website: value })
            }
            onBlur={(e) => updateField("website", economyActivity.website)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues('mensualEntry')}
            label="Ingreso Mensual (Fuente principal de ingreso)"
            type="number"
            placeholder="Ingreso mensual"
            error="Campo obligatorio"
            showError={economyActivity.mensualEntry === 0 && showErrors}
            value={economyActivity.mensualEntry}
            iconLeft={
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign has-text-black"></i>
              </span>
            }
            onChange={({ target: { value } }) =>
              setEconomyActivity({ ...economyActivity, mensualEntry: value })
            }
            onBlur={(e) =>
              updateField("mensualEntry", economyActivity.mensualEntry)
            }
          />
        </div>
        {!isBusiness && (
          <>
            <hr />
            <div className="columns column is-12">
              <div className="column is-6">
                <p className="is-size-5 has-text-primary has-text-weight-bold	">
                  Otros ingresos
                </p>
                <br />
                <label className="checkbox" style={{ marginRight: "30px" }}>
                  <input
                    disabled={isFieldWithIssues('otherEntry')}
                    id="ingreso_de_la_pareja"
                    type="checkbox"
                    checked={
                      economyActivity.otherEntry.indexOf(
                        "ingreso_de_la_pareja"
                      ) !== -1
                    }
                    onClick={addOtherEntry}
                  />
                  &nbsp; Ingreso de la pareja
                </label>
                <label className="checkbox" style={{ marginRight: "30px" }}>
                  <input
                    disabled={isFieldWithIssues('otherEntry')}
                    id="negocio"
                    type="checkbox"
                    checked={
                      economyActivity.otherEntry.indexOf("negocio") !== -1
                    }
                    onClick={addOtherEntry}
                  />
                  &nbsp; Negocio
                </label>

                <label className="checkbox" style={{ marginRight: "30px" }}>
                  <input
                    disabled={isFieldWithIssues('otherEntry')}
                    id="empleo"
                    type="checkbox"
                    checked={
                      economyActivity.otherEntry.indexOf("empleo") !== -1
                    }
                    onClick={addOtherEntry}
                  />
                  &nbsp; Empleo
                </label>
                <label className="checkbox" style={{ marginRight: "30px" }}>
                  <input
                    disabled={isFieldWithIssues('otherEntry')}
                    id="otros"
                    type="checkbox"
                    checked={economyActivity.otherEntry.indexOf("otros") !== -1}
                    onClick={addOtherEntry}
                  />
                  &nbsp; Otros
                </label>
              </div>
              <div className="column is-2">
                {economyActivity.otherEntry.indexOf("otros") !== -1 && (
                  <Input
                    disabled={isFieldWithIssues('otherEntryName')}
                    label="Especifique"
                    type="text"
                    error="Campo obligatorio"
                    showError={
                      economyActivity.otherEntryName === "" && showErrors
                    }
                    placeholder="Descripción corta"
                    value={economyActivity.otherEntryName}
                    onChange={({ target: { value } }) =>
                      setEconomyActivity({
                        ...economyActivity,
                        otherEntryName: value,
                      })
                    }
                    onBlur={(e) =>
                      updateField(
                        "otherEntryName",
                        economyActivity.otherEntryName
                      )
                    }
                  />
                )}
              </div>
            </div>
            <hr />
            {economyActivity.otherEntry.length !== 0 && (
              <div className="column is-3">
                <Input
                  disabled={isFieldWithIssues('otherEntryAmmount')}
                  label="Total de otros ingresos"
                  type="number"
                  iconLeft={
                    <span className="icon is-small is-left">
                      <i className="fas fa-dollar-sign has-text-black"></i>
                    </span>
                  }
                  placeholder="Mensuales"
                  //error="Campo obligatorio"
                  //showError={ economyActivity.otherEntry.length !== 0 && !economyActivity.otherEntryAmmount && showErrors}
                  value={economyActivity.otherEntryAmmount ?? 0}
                  onChange={({ target: { value } }) => {
                    setEconomyActivity({
                      ...economyActivity,
                      otherEntryAmmount: value,
                    });
                  }}
                  onBlur={(e) =>
                    updateField(
                      "otherEntryAmmount",
                      economyActivity.otherEntryAmmount
                    )
                  }
                />
              </div>
            )}
          </>
        )}
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues('otherEntryAmmount')}
            label="Total de ingresos mensuales"
            type="number"
            iconLeft={
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign has-text-black"></i>
              </span>
            }
            value={
              parseInt((economyActivity.otherEntryAmmount == null  || economyActivity.otherEntryAmmount == '' ) ? '0' : economyActivity.otherEntryAmmount + '') +
              parseInt(economyActivity.mensualEntry)
            }
          />
        </div>

        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues('totalMonthExpenses')}
            label="Gastos mensuales (sin renta)"
            type="number"
            iconLeft={
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign has-text-black"></i>
              </span>
            }
            placeholder="Sin considerar renta"
            error="Campo obligatorio"
            showError={!economyActivity.totalMonthExpenses && showErrors}
            value={economyActivity.totalMonthExpenses}
            onChange={({ target: { value } }) =>
              setEconomyActivity({
                ...economyActivity,
                totalMonthExpenses: value,
              })
            }
            onBlur={(e) =>
              updateField(
                "totalMonthExpenses",
                economyActivity.totalMonthExpenses
              )
            }
          />
        </div>
      </div>

      <div className="columns is-multiline">
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fas fa-money-bill-wave-alt fa-lg"></i>
            </span>
            <br />
            <b>
              3 últimos comprobantes de ingreso.
              {isBusiness && (
                <>
                  <br />
                  Declaraciones de ISR o estados de cuenta
                </>
              )}
            </b>
            <br />
            {files.entry.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) =>
                  isFieldWithIssues('entryFile') ? null : targetFile("entry", e.target.id)
                }
              >
                Selecciona un archivo .pdf
              </button>
            ) : (
              files.entry.map((f, i) => (
                <div
                  key={i}
                  className="tag is-medium is-rounded is-success has-text-white"
                  style={{ margin: "5px" }}
                >
                  Documento: {i + 1}
                  <button
                    className="delete is-small"
                    onClick={() => (isFieldWithIssues('entryFile') ? null : deleteFile("entry", i))}
                  ></button>
                </div>
              ))
            )}
            <input
              onChange={setFile}
              multiple={true}
              className="is-hidden"
              type="file"
              name="entry"
              id="entry"
            />
          </div>
        </div>
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fas fa-money-bill-wave-alt fa-lg"></i>
            </span>
            <br />
            <b>
              Comprobantes de otros ingresos
            </b>
            <br />
            {files.other.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) =>
                  isFieldWithIssues('otherFile') ? null : targetFile("other", e.target.id)
                }
              >
                Seleccionar archivos
              </button>
            ) : (
              files.other.map((f, i) => (
                <div
                  key={i}
                  className="tag is-medium is-rounded is-success has-text-white"
                  style={{ margin: "5px" }}
                >
                  Documento: {i + 1}
                  <button
                    className="delete is-small"
                    onClick={() => (isFieldWithIssues('otherFile') ? null : deleteFile("other", i))}
                  ></button>
                </div>
              ))
            )}
            <input
              onChange={setFile}
              multiple={true}
              className="is-hidden"
              type="file"
              name="other"
              id="other"
            />
          </div>
        </div>
      </div>
      <div className="column is-12">
        <div className="notification is-light is-primary">
          <div className="field">
            <label className="checkbox" style={{ marginRight: "30px" }}>
              <input
                id="check_credit_historial"
                type="checkbox"
                defaultChecked={economyActivity.checkCreditHistorial}
                onChange={(e) => {
                  setEconomyActivity({
                    ...economyActivity,
                    checkCreditHistorial: e.target.checked,
                  });
                  updateField("checkCreditHistorial", e.target.checked);
                  if (e.target.checked) {
                    setShowDialog(true);
                  }
                }}
              />
              &nbsp; Acepto terminos y condiciones y cláusula de medios electronicos tales como NIP
            </label>
            {showErrors && !economyActivity.checkCreditHistorial && (
              <p className="help is-danger">
                Debes autorizar la consulta de historial crediticio.
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="navbar">
        <div className="navbar-start"></div>
        <div className="navbar-end">
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={next}
          >
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
      {showDialog && <CreditHistoryAuth show={showDialog} close={setShowDialog}/>}
    </div>
  );
};
