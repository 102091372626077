import React, { useContext, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "../../hooks/useQuery";
import AuthContext from "../../services/authService/context";

export const NavStyle = styled.nav`
  box-shadow: ${({ shadow }) =>
    shadow ? "0px 5px 4px 1px #dcdcdc45" : "none"};
  position: ${({ absolute }) => (absolute ? "absolute" : "static")};
  width: 100%;

  &.panel-menu {
    z-index: 9;
    background: #20555b;

    a.navbar-item {
      &:not(.button) {
        color: white;
      }
      border-radius: 0px !important;
      transition: all 0.1s ease;
      &.is-active,
      &:hover {
        box-shadow: inset 0px -2px 0 0px white;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .navbar-burger {
      span {
        background-color: #fff !important;
      }
    }
    .navbar-item {
      color: #363636 !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .navbar-brand {
      float: left;
      min-height: 3rem;
      height: 3rem;
    }

    .renter-navbar {
      a.navbar-item {
        color: #363636 !important;

        &.is-active {
          background: #20555b !important;
          color: white !important;
        }
      }
    }
  }
`;

export const RenterMenu = (props) => {
  const query = useQuery();
  const procedure = query.get("procedure");
  const [collapse, setCollapse] = useState("");
  const queryParam = procedure ? `?procedure=${procedure}` : "";
  return (
    <NavStyle className="navbar panel-menu" shadow={true}>
      <div
        className={`navbar-burger burger ${collapse}`}
        data-target="panelNavbar"
        onClick={(e) =>
          collapse === "is-active" ? setCollapse("") : setCollapse("is-active")
        }
      >
        <span className="has-background-white"></span>
        <span className="has-background-white"></span>
        <span className="has-background-white"></span>
      </div>
      <div id="panelNavbar" className={`navbar-menu renter-navbar ${collapse}`}>
        <div className="navbar-start">
          <NavLink
            to={`/panel/información${queryParam}`}
            className="navbar-item has-text-weight-bold"
            activeClassName="is-active"
          >
            Información
          </NavLink>
          <NavLink
            to="/panel/mi-investigacion"
            className="navbar-item has-text-weight-bold"
            activeClassName="is-active"
          >
            Mi investigación
          </NavLink>
        </div>

        <div className="navbar-end is-hidden">
          <div className="navbar-item">
            <p className="control">
              <NavLink
                to="/panel/mis-tramites/nuevo"
                className="button is-white is-outlined"
                activeClassName="is-active"
              >
                <span>Nuevo trámite</span>
                <span className="icon">
                  <i className="fas fa-plus"></i>
                </span>
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </NavStyle>
  );
};

export const Navbar = (props) => {
  const [collapse, setCollapse] = useState("");
  const authContext = useContext(AuthContext);

  let isIndex = window.location.pathname === "/";

  return (
    <NavStyle
      className={`navbar ${props.shadow ? "" : " is-transparent"}`}
      shadow={props.shadow}
      absolute
    >
      <div className="navbar-brand">
        <a className="navbar-item no-hoverable" href="/">
          <img
            src={`/assets/${!props.shadow ? "logo.png" : "logo-colored.png"}`}
            alt="Invix, la mejor plataforma de investigación de inquilinos."
            style={{ height: "50px", maxHeight: "50px" }}
          />
        </a>
      </div>
      <div
        className={`navbar-burger burger ${collapse}`}
        data-target="invixNavbar"
        onClick={(e) =>
          collapse === "is-active" ? setCollapse("") : setCollapse("is-active")
        }
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="invixNavbar" className={`navbar-menu ${collapse}`}>
        <div className="navbar-start">
          {/* <a className="navbar-item has-text-weight-bold" href={isIndex ? "#inicio" : "/?showLandingPage=true"}>
            Inicio
          </a> */}
          {!props.hideItems ? (
            <>
              <a
                className="navbar-item has-text-weight-bold has-text-white"
                href="#conocenos"
              >
                Conocénos
              </a>
              <a
                className="navbar-item has-text-weight-bold has-text-white"
                href="#beneficios"
              >
                Beneficios
              </a>
              <a
                className="navbar-item has-text-weight-bold has-text-white"
                href="#precios"
              >
                Precios
              </a>
              <a
                className="navbar-item has-text-weight-bold has-text-white"
                href="#comofunciona"
              >
                ¿Cómo funciona?
              </a>
              <a
                className="navbar-item has-text-weight-bold has-text-white"
                href="http://partners.invix.mx"
              >
                Acceso a inmobiliarias
              </a>
            </>
          ) : null}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            {authContext.authStatus === 2 ? (
              <div className="field is-grouped panel-menu">
                <NavLink
                  to="/panel/informacion"
                  className="navbar-item has-text-weight-bold"
                  activeClassName="is-active"
                >
                  Información
                </NavLink>
                <NavLink
                  to="/panel/mi-investigacion"
                  className="navbar-item has-text-weight-bold"
                  activeClassName="is-active"
                >
                  Mi investigación
                </NavLink>
                <NavLink className="navbar-item" to="/panel/mi-cuenta">
                  Mi cuenta
                </NavLink>
                {isIndex ? (
                  <NavLink
                    className="button navbar-item is-primary is-small"
                    to="/panel/mi-cuenta"
                  >
                    Ir a Invix
                  </NavLink>
                ) : (
                  <NavLink
                    className="navbar-item has-text-danger"
                    to="/cerrar-sesion"
                  >
                    Salir
                  </NavLink>
                )}
              </div>
            ) : (
              <div className="field is-grouped">
                <p className="control">
                  <NavLink
                    className="navbar-item has-text-weight-bold has-text-white"
                    to="/acceder"
                  >
                    <span>Iniciar sesión</span>
                  </NavLink>
                </p>
                <p className="control">
                  <NavLink
                    className="button is-info"
                    style={{ backgroundColor: "#71BEC3" }}
                    to="/crear-cuenta"
                  >
                    <span>Registrarse</span>
                  </NavLink>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavStyle>
  );
};
