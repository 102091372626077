import React from 'react'
import { Padding } from '../../components/Padding'
import { Logout } from '../../services/User';

export const SignOut = (props) => {
  return (
    <Padding padding='100px' className="login">
      <div className="notification is-white" style={{
        maxWidth: '500px',
        margin: '0 auto',
        boxShadow: 'rgba(220, 220, 220, 0.27) 0px 3px 6px 8px',
      }}>
        <div>
          <div className="has-text-centered">
            <img src="/assets/logo.png" alt="Invix" className="logo is-centered"/>
          </div>
          <br/>
          <p className="title is-3 has-text-centered">
            ¿Cerrar sesión?
          </p>
          <button className="button is-primary is-fullwidth" onClick={(e) => {
            Logout();
            window.location = "/";
          }}>
            Sí, cerrar sesión
          </button>
          <br/>
          <button className="button is-light has-text-primary is-fullwidth" onClick={(e) => {
            props.history.goBack();
          }}>
            No, regresar
          </button>
        </div>
      </div>
    </Padding>
  )
}
