import React, { useState } from 'react'
import { ResetPassword } from '../../services/User'

/*
 * ForgotPassword
 */ 
export const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')


  const forgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      let { data: { message: Message } } = await ResetPassword({
        email,
      })
      setMessage(Message);
    } catch ({ response: { data: { message: Message } } }) {
      setError(Message);
    }
  }

  return (
    <div>
      <div className="has-text-centered">
        <img src="/assets/logo.png" alt="Invix" className="logo is-centered"/>
      </div>
      <br/>     
      <p className="title is-3 has-text-centered">
        Recuperar Contraseña
      </p>
      {
        error !== '' ? <div>
          <div className="notification is-danger has-text-white">
            { error }
          </div> 
          <br/>
        </div>: null
      }
      {
        message !== '' ? <div>
          <div className="notification is-primary has-text-white">
            { message }
          </div> 
          <br/>
        </div>: null
      }
      <form onSubmit={forgotPassword}>
        <div className="field">
          <label className="label">Correo electrónico</label>
          <div className="control">
            <input className="input is-medium" type="email" placeholder="ejemplo@ejemplo.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>
        <hr/>
          <button className="button is-primary is-fullwidth" type="submit">
            Enviar contraseña nueva
          </button>
        </form>
        <br/>
    </div>
  )
}

export default ForgotPasswordForm;

