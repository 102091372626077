import React from 'react'

export const FullScreenLoader = (props) => {
  return (
    <div>
      Cargando...
    </div>
  )
}


export const Loader = (props) => {
  return (
    <div>
      Cargando...
    </div>
  )
}