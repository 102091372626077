import React, { useState, useContext, useEffect } from "react";
import { Input } from "../../../../components/Form";
import Dialog from "../../../../components/Dialog";
import {
  Models,
  UpdateField,
  UploadFile,
  GetSection,
} from "../../../../services/Renter";
import { RenterInformationContext } from "../../../../services/Renter/context";
import moment from "moment";
import { isObjectFilled } from "../../../../Utils";
import { useQuery } from "../../../../hooks/useQuery";
import boleta from "./boleta.png";
export const STS = [
  "AS",
  "BC",
  "BS",
  "CC",
  "CS",
  "CH",
  "DF",
  "CL",
  "CM",
  "DG",
  "GT",
  "GR",
  "HG",
  "JC",
  "MC",
  "MN",
  "MS",
  "NT",
  "NL",
  "OC",
  "PL",
  "QO",
  "QR",
  "SL",
  "SR",
  "TC",
  "TS",
  "TL",
  "VZ",
  "YN",
  "ZS",
];

export const states = {
  AS: "AGUASCALIENTES",
  BC: "BAJA CALIFORNIA",
  BS: "BAJA CALIFORNIA SUR",
  CC: "CAMPECHE",
  CS: "CHIAPAS",
  CH: "CHIHUAHUA",
  DF: "CIUDAD DE MÉXICO",
  CL: "COAHUILA",
  CM: "COLIMA",
  DG: "DURANGO",
  GT: "GUANAJUATO",
  GR: "GUERRERO",
  HG: "HIDALGO",
  JC: "JALISCO",
  MC: "MÉXICO",
  MN: "MICHOACÁN ",
  MS: "MORELOS",
  NT: "NAYARIT",
  NL: "NUEVO LEÓN",
  OC: "OAXACA",
  PL: "PUEBLA",
  QO: "QUERÉTARO",
  QR: "QUINTANA ROO",
  SL: "SINALOA",
  SR: "SONORA",
  TC: "TABASCO",
  TS: "TAMAULIPAS",
  TL: "TLAXCALA",
  VZ: "VERACRUZ",
  YN: "YUCATÁN",
  ZS: "ZACATECAS",
};

export const regexCupr =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const initialData = {
  name: "",
  birthdate: "",
  birthPlace: "",
  curp: "",
  nationality: "",
  maritialStatus: "",
  spouseName: "",
  spousePhone: "",
  email: "",
  phone: "",
  address: "",
  isBusiness: false,
  businessName: "",
  businessRFC: "",
  businessDate: "",
  businessAddress: "",
  businessNationality: "",
  businessPhone: "",
};

const initialProperty = {
  address: "",
  assessment: "",
  assessmentName: "",
};

export const GuarantorData = (props) => {
  const query = useQuery();
  const procedureId = query.get("procedure");
  const [showAssessmentField, setShowAssessmentField] = useState(false);
  const { guarantorData: defaultData } = props;
  const { guarantorProperty: defaultProperty } = props;
  const { isFieldWithIssues, completetSection } = useContext(
    RenterInformationContext
  );
  const [showHelpModal, setShowHelpModal] = useState(false);
  let date = new Date(defaultData.birthdate);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  const [guarantorData, setGuarantorData] = useState({
    ...initialData,
    ...defaultData,
    birthdate: moment(date).format("yyyy-MM-DD"),
  });
  const [files, setFiles] = useState({
    ine: guarantorData.ineFile || [],
    dom: guarantorData.domFile || [],
    owner: guarantorData.ownerFile || [],
    owner: guarantorData.ownerFile || [],
    act: guarantorData.actFile || [],
    fiscal: guarantorData.fiscalFile || [],
    power: guarantorData.powerFile || [],
    houseDeeds: guarantorData.houseDeedsFile || [],
    registrationTicket: guarantorData.registrationTicketFile || [],
    predialPayment: guarantorData.predialPaymentFile || [],
  });

  const [guarantorProperty, setGuarantorProperty] = useState({
    ...initialProperty,
    ...defaultProperty,
  });

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    async function getGuarantorData() {
      let { data } = await GetSection({
        section: Models.guarantorData,
      });
      setGuarantorData(data);
      setFiles({
        ine: data.ineFile || [],
        dom: data.domFile || [],
        owner: data.ownerFile || [],
        owner: data.ownerFile || [],
        act: data.actFile || [],
        fiscal: data.fiscalFile || [],
        power: data.powerFile || [],
        houseDeeds: data.houseDeedsFile || [],
        registrationTicket: data.registrationTicketFile || [],
        predialPayment: data.predialPaymentFile || [],
      });
    }
    async function getGuarantorProperty() {
      let { data } = await GetSection({
        section: Models.guarantorProperty,
      });
      setGuarantorProperty(data);
    }
    getGuarantorData();
    getGuarantorProperty();
  }, []);

  const updateIsBusiness = async (hasIsBusiness) => {
    await UpdateField({
      modelName: Models.guarantorData,
      update: {
        isBusiness: hasIsBusiness,
      },
    });
    localStorage.setItem("section", "GUARANTOR_DATA");
    window.location.href = "/panel/informacion/fiador";
  };

  const updateGuarantorData = async (field, value) => {
    setLoading(field);
    let {
      // TODO add fields
      spouseName,
      maritialStatus,
      ...rest
    } = guarantorData;
    let completed = isObjectFilled(rest);

    /*if(!completedSection && completed) {
      completetSection('guarantorData');
    }*/

    await UpdateField({
      modelName: Models.guarantorData,
      update: {
        [field]: value,
        completed,
      },
    });

    setLoading("");
  };

  const updateGuarantorProperty = async (field, value) => {
    setLoading(field);
    let {
      // TODO add fields
      assessmentName,
      ...rest
    } = guarantorProperty;
    let completed = isObjectFilled(rest);

    await UpdateField({
      modelName: Models.guarantorProperty,
      update: {
        [field]: value,
        completed,
      },
    });

    console.log({
      [field]: value,
      completed,
    });

    setLoading("");
  };

  const next = async () => {
    var {
      businessName,
      businessRFC,
      businessAddress,
      businessNationality,
      businessPhone,
      spouseName,
      spousePhone,
      notes,
      ...rest
    } = guarantorData;

    let completedGuarantorData = isObjectFilled(rest);

    let {
      // TODO add fields
      maritialStatus,
      assessmentName,
      ...restProperty
    } = guarantorProperty;

    let completedGuarantorProperty = isObjectFilled(restProperty);

    await UpdateField({
      modelName: Models.renter,
      update: {
        completed: true,
      },
    });
    return props.history.push("/pagar");
  };

  const targetFile = (file, id) => {
    document.getElementById(file).click();
  };

  const setFile = async (e) => {
    let filesSelected = e.target.files;
    let data = new FormData();
    for (let i = 0; i < filesSelected.length; i++) {
      data.append(filesSelected[i].name, filesSelected[i]);
    }
    data.append("fileName", e.target.id);
    data.append("modelName", Models.guarantorData);
    let {
      data: { urls },
    } = await UploadFile(data);
    setFiles({ ...files, [e.target.id]: [...urls] });
  };

  const deleteFile = (file, indexFile) => {
    let filesUpdate = files[file];
    filesUpdate.splice(indexFile, 1);
    setFiles({ ...files, [file]: [...filesUpdate] });
  };

  return (
    <div className="info-form">
      <div className="navbar">
        <div className="navbar-start">
          <div>
            <p className="title is-4 has-text-primary">
              Información del fiador
            </p>
            <p className="subtitle is-5">
              Los datos se guardan automáticamente
            </p>
          </div>
        </div>
        <div className="navbar-end">
          {procedureId == null && (
            <button
              className="button is-primary is-fullwidth-mobile"
              onClick={() => {
                next();
              }}
            >
              <span>Pagar y enviar</span>
              <span className="icon">
                <i className="fas fa-check-circle"></i>
              </span>
            </button>
          )}
        </div>
      </div>
      <br />
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          <button
            className="button is-light is-info"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>
              {" "}
              El fiados es:{" "}
              <b>
                {guarantorData.isBusiness ? "Persona Moral" : "Persona Física"}
              </b>
            </span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div
          className="dropdown-menu is-fullwidth"
          id="dropdown-menu"
          role="menu"
        >
          <div className="dropdown-content">
            <a
              href="#"
              className="dropdown-item"
              onClick={() => {
                updateIsBusiness(!guarantorData.isBusiness);
              }}
            >
              Cambiar a: &nbsp;
              <b>
                {guarantorData.isBusiness ? "Persona Física" : "Persona Moral"}
              </b>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="columns is-multiline">
        {guarantorData.isBusiness && (
          <>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("businessName")}
                label="Razón social"
                type="text"
                placeholder="Razón social"
                value={guarantorData.businessName}
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, businessName: value })
                }
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData(
                    "businessName",
                    guarantorData.businessName
                  )
                }
              />
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("businessDate")}
                label="Fecha de constitución de la sociedad"
                type="date"
                placeholder=""
                value={guarantorData.businessDate}
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, businessDate: value })
                }
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData(
                    "businessDate",
                    guarantorData.businessDate
                  )
                }
              />
            </div>
            <div className="column is-4">
              <div className="field">
                <label className="label">Nacionalidad</label>
                <div className="control">
                  <div
                    className={`select is-fullwidth ${
                      loading === "businessNationality" ? "is-loading" : ""
                    }`}
                  >
                    <select
                      disabled={isFieldWithIssues("businessNationality")}
                      value={guarantorData.businessNationality}
                      onChange={({ target: { value } }) => {
                        setGuarantorData({
                          ...guarantorData,
                          businessNationality: value,
                        });
                        updateGuarantorData("businessNationality", value);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      <option value="mexicana">Mexicana</option>
                      <option value="extranjera">Extranjera</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("businessRFC")}
                label="RFC"
                type="text"
                maxLength="11"
                placeholder="RFC de la empresa"
                value={guarantorData.businessRFC}
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, businessRFC: value })
                }
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData("businessRFC", guarantorData.businessRFC)
                }
              />
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("businessAddress")}
                label="Domicilio"
                type="text"
                placeholder="Domicilio de la empresa"
                value={guarantorData.businessAddress}
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, businessAddress: value })
                }
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData(
                    "businessAddress",
                    guarantorData.businessAddress
                  )
                }
              />
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("businessPhone")}
                label="Teléfono"
                type="text"
                maxLength="10"
                placeholder="Teléfono de la empresa"
                value={guarantorData.businessPhone}
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, businessPhone: value })
                }
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData(
                    "businessPhone",
                    guarantorData.businessPhone
                  )
                }
              />
            </div>
          </>
        )}

        {guarantorData.isBusiness && (
          <div className="column is-12">
            <hr />
            <p className="is-uppercase has-text-weight-bold	 has-text-info">
              Datos del representante legal
            </p>
          </div>
        )}
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("name")}
            label="Nombre Completo"
            type="text"
            placeholder="Nombre(s) Apellido(s)"
            value={guarantorData.name}
            onChange={({ target: { value } }) =>
              setGuarantorData({ ...guarantorData, name: value })
            }
            error="Este campo es obligatorio"
            onBlur={(e) => updateGuarantorData("name", guarantorData.name)}
          />
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">Nacionalidad</label>
            <div className="control">
              <div
                className={`select is-fullwidth ${
                  loading === "nationality" ? "is-loading" : ""
                }`}
              >
                <select
                  disabled={isFieldWithIssues("nationality")}
                  value={guarantorData.nationality}
                  onChange={({ target: { value } }) => {
                    setGuarantorData({ ...guarantorData, nationality: value });
                    updateGuarantorData("nationality", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  <option value="mexicana">Mexicana</option>
                  <option value="extranjera">Extranjera</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("nationality")}
            isLoading={loading === "curp"}
            label={
              guarantorData.nationality === "mexicana" ||
              !guarantorData.nationality
                ? "C.U.R.P."
                : "Clave De Documento Migratorio"
            }
            type="text"
            placeholder=""
            onChange={({ target: { value } }) => {
              setGuarantorData({ ...guarantorData, curp: value });
            }}
            value={guarantorData.curp}
            helper={
              guarantorData.nationality === "mexicana" ||
              !guarantorData.nationality ? (
                <a
                  href="https://www.gob.mx/curp/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  ¿No sabes tu CURP?
                </a>
              ) : null
            }
            //error={ (guarantorData.nationality === 'mexicana' || !guarantorData.nationality) && "Formato incorrecto de CURP"}
            showError={guarantorData.curp === "" && showErrors}
            onBlur={(e) => {
              updateGuarantorData("curp", e.target.value);
              let state = e.target.value.substr(11, 2);
              /*let year = guarantorData.curp.substr(4, 2);
              let month = guarantorData.curp.substr(4, 2);
              let day = guarantorData.curp.substr(4, 2);*/
              if (STS.indexOf(state) !== -1) {
                setGuarantorData({
                  ...guarantorData,
                  birthPlace: states[state].toUpperCase(),
                });
                updateGuarantorData("birthPlace", states[state].toUpperCase());
              }
            }}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("birthdate")}
            isLoading={loading === "birthdate"}
            label="Fecha de nacimiento"
            type="date"
            placeholder="Fecha de nacimiento"
            onChange={({ target: { value } }) =>
              setGuarantorData({ ...guarantorData, birthdate: value })
            }
            value={guarantorData.birthdate}
            error="Este campo es obligatorio"
            showError={guarantorData.birthdate === "" && showErrors}
            onBlur={(e) =>
              updateGuarantorData("birthdate", guarantorData.birthdate)
            }
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("birthPlace")}
            isLoading={loading === "birthPlace"}
            label="Lugar de nacimiento"
            type="text"
            placeholder="Lugar de nacimiento"
            onChange={({ target: { value } }) =>
              setGuarantorData({ ...guarantorData, birthPlace: value })
            }
            value={guarantorData.birthPlace}
            error="Este campo es obligatorio"
            showError={guarantorData.birthPlace === "" && showErrors}
            onBlur={(e) =>
              updateGuarantorData("birthPlace", guarantorData.birthPlace)
            }
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("address")}
            isLoading={loading === "address"}
            label="Domicilio"
            type="text"
            placeholder="Calle número colonia ciudad estado CP"
            onChange={({ target: { value } }) =>
              setGuarantorData({ ...guarantorData, address: value })
            }
            value={guarantorData.address}
            onBlur={(e) =>
              updateGuarantorData("address", guarantorData.address)
            }
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("phone")}
            isLoading={loading === "phone"}
            label="Teléfono"
            type="tel"
            maxLength="10"
            placeholder="10 Dígitos"
            onChange={({ target: { value } }) =>
              setGuarantorData({ ...guarantorData, phone: value })
            }
            value={guarantorData.phone}
            onBlur={(e) => updateGuarantorData("phone", guarantorData.phone)}
          />
        </div>
        {!guarantorData.isBusiness && (
          <div className="column is-4">
            <Input
              disabled={isFieldWithIssues("email")}
              isLoading={loading === "email"}
              label="Correo electrónico"
              type="email"
              placeholder="Opcional"
              onChange={({ target: { value } }) =>
                setGuarantorData({ ...guarantorData, email: value })
              }
              value={guarantorData.email}
              onBlur={(e) => updateGuarantorData("email", guarantorData.email)}
            />
          </div>
        )}
        {!guarantorData.isBusiness && (
          <div className="column is-2">
            <div className="field">
              <label className="label">Estado civil</label>
              <div className="control">
                <div
                  className={`select is-fullwidth ${
                    loading === "maritialStatus" ? "is-loading" : ""
                  }`}
                >
                  <select
                    disabled={isFieldWithIssues("maritialStatus")}
                    value={guarantorData.maritialStatus}
                    onChange={({ target: { value } }) => {
                      setGuarantorData({
                        ...guarantorData,
                        maritialStatus: value,
                      });
                      updateGuarantorData("maritialStatus", value);
                    }}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    <option value="soltero">Soltero(a)</option>
                    <option value="casado">Casado(a)</option>
                    <option value="unionlibre">Union libre</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {["casado", "unionlibre"].indexOf(guarantorData.maritialStatus) !==
        -1 ? (
          <>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("spouseName")}
                isLoading={loading === "spouseName"}
                label="Nombre del cónyuge"
                type="text"
                placeholder="Nombre completo"
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, spouseName: value })
                }
                value={guarantorData.spouseName}
                error="Este campo es obligatorio"
                showError={guarantorData.spouseName === "" && showErrors}
                onBlur={(e) =>
                  updateGuarantorData("spouseName", guarantorData.spouseName)
                }
              />
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("spousePhone")}
                isLoading={loading === "spousePhone"}
                label="Teléfono del cónyuge"
                type="tel"
                maxLength="10"
                placeholder="Teléfono completo"
                onChange={({ target: { value } }) =>
                  setGuarantorData({ ...guarantorData, spousePhone: value })
                }
                value={guarantorData.spousePhone}
                error="Este campo es obligatorio"
                onBlur={(e) =>
                  updateGuarantorData("spousePhone", guarantorData.spousePhone)
                }
              />
            </div>
          </>
        ) : null}
      </div>
      <p className="is-uppercase has-text-weight-bold	 has-text-info">
        Inmueble garantia del fiador
      </p>
      <br />
      <div className="columns is-multiline">
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("address")}
            isLoading={loading === "address"}
            label="Domicilio"
            type="text"
            placeholder="Domicilio completo"
            onChange={({ target: { value } }) =>
              setGuarantorProperty({ ...guarantorProperty, address: value })
            }
            value={guarantorProperty.address}
            error="Este campo es obligatorio"
            showError={guarantorProperty.address === "" && showErrors}
            onBlur={(e) =>
              updateGuarantorProperty("address", guarantorProperty.address)
            }
          />
        </div>
        <div className="column is-3">
          <div className="field">
            <label className="label">Reporta algún gravamen</label>
            <div className="control">
              <div
                className={`select is-fullwidth ${
                  loading === "assessment" ? "is-loading" : ""
                }`}
              >
                <select
                  disabled={isFieldWithIssues("assessment")}
                  value={guarantorProperty.assessment}
                  onChange={({ target: { value } }) => {
                    setGuarantorProperty({
                      ...guarantorProperty,
                      assessment: value,
                    });
                    updateGuarantorProperty("assessment", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {guarantorProperty.assessment === "si" && (
          <div className="column is-2">
            <div className="field">
              <label className="label">Tipo de gravamen</label>
              <div className="control">
                <div
                  className={`select is-fullwidth ${
                    loading === "assessmentName" ? "is-loading" : ""
                  }`}
                >
                  <select
                    disabled={isFieldWithIssues("assessmentName")}
                    value={guarantorProperty.assessmentName}
                    onChange={({ target: { value } }) => {
                      if (value === "otro") setShowAssessmentField(true);
                      else setShowAssessmentField(false);
                      setGuarantorProperty({
                        ...guarantorProperty,
                        assessmentName: value,
                      });
                      updateGuarantorProperty("assessmentName", value);
                    }}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    <option value="Hipoteca">Hipoteca</option>
                    <option value="Embargo">Embargo</option>
                    <option value="Litigio">Litigio</option>
                    <option value="Usufructo vitalicio">
                      Usufructo vitalicio
                    </option>
                    <option value="otro">Otro</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        {showAssessmentField && (
          <div className="column is-3">
            <Input
              disabled={isFieldWithIssues("gravamen")}
              isLoading={loading === "gravamen"}
              label="Especifique"
              type="text"
              placeholder="Tipo de gravamen"
              onChange={({ target: { value } }) =>
                setGuarantorProperty({ ...guarantorProperty, gravamen: value })
              }
              value={guarantorProperty.gravamen}
              error="Campo obligatorio"
              showError={
                setShowAssessmentField &&
                showErrors &&
                guarantorProperty.gravamen === ""
              }
              onBlur={(e) =>
                updateGuarantorProperty("gravamen", guarantorProperty.gravamen)
              }
            />
          </div>
        )}
      </div>
      <hr />
      <p className="is-size-5 has-text-primary has-text-weight-bold	">
        Documentos:
      </p>
      <hr />
      <div className="columns is-multiline">
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fas fa-id-card fa-lg"></i>
            </span>
            <br />
            <b>
              Identificación oficial{" "}
              {guarantorData.isBusiness && "del representante legal"}
            </b>
            <br />
            {files.ine.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) =>
                  isFieldWithIssues("ineFile")
                    ? null
                    : targetFile("ine", e.target.id)
                }
              >
                Seleccionar INE
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white">
                INE Cargado
                <button
                  className="delete is-small"
                  onClick={() =>
                    isFieldWithIssues("ineFile") ? null : deleteFile("ine", 0)
                  }
                ></button>
              </div>
            )}
          </div>
          <input
            disabled={isFieldWithIssues("ineFile")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="ine"
            id="ine"
          />
        </div>
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fa fa-home fa-lg"></i>
            </span>
            <br />
            <b>
              Comprobante de domicilio{" "}
              {guarantorData.isBusiness && "de la persona moral"}
            </b>
            <br />
            {files.dom.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) =>
                  isFieldWithIssues("domFile")
                    ? null
                    : targetFile("dom", e.target.id)
                }
              >
                Seleccionar comprobante de domicilio
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white">
                Comprobante Cargado
                <button
                  className="delete is-small"
                  onClick={() =>
                    isFieldWithIssues("domFile") ? null : deleteFile("dom", 0)
                  }
                ></button>
              </div>
            )}
            <input
              disabled={isFieldWithIssues("domFile")}
              onChange={setFile}
              multiple={true}
              className="is-hidden"
              type="file"
              name="dom"
              id="dom"
            />
          </div>
        </div>
        <div className="column is-4">
          <div
            className={`notification has-text-centered  ${
              showErrors &&
              (files.houseDeeds.length == 0 ||
                files.registrationTicket.length == 0)
                ? "is-danger is-light"
                : ""
            } `}
          >
            <span className="icon">
              <i className="fas fa-file-alt fa-lg"></i>
            </span>
            <br />
            <b>
              Comprobante de titularidad del Inmueble
              <br />
              <span
                className="has-text-link as-cursor has-tooltip-top"
                data-tooltip="Da clic para ver un ejemplo"
                onClick={() => setShowHelpModal(true)}
              >
                Escrituras, boleta registral y pago de predial
              </span>
            </b>
            <br />
            {files.houseDeeds.length === 0 ? (
              <button
                className="button is-primary is-rounded mb-2"
                onClick={(e) =>
                  isFieldWithIssues("houseDeeds")
                    ? null
                    : targetFile("houseDeeds", e.target.id)
                }
              >
                Seleccionar escrituras
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white mb-2">
                Escrituras cargadas
                <button
                  className="delete is-small"
                  onClick={() =>
                    isFieldWithIssues("houseDeeds")
                      ? null
                      : deleteFile("houseDeeds", 0)
                  }
                ></button>
              </div>
            )}
            {files.registrationTicket.length === 0 ? (
              <button
                className="button is-primary is-rounded mb-2"
                onClick={(e) =>
                  isFieldWithIssues("registrationTicket")
                    ? null
                    : targetFile("registrationTicket", e.target.id)
                }
              >
                Seleccionar boleta registral
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white mb-2">
                Boleta cargada
                <button
                  className="delete is-small"
                  onClick={() =>
                    isFieldWithIssues("registrationTicket")
                      ? null
                      : deleteFile("registrationTicket", 0)
                  }
                ></button>
              </div>
            )}
            {files.predialPayment.length === 0 ? (
              <button
                className="button is-primary is-rounded mb-2"
                onClick={(e) =>
                  isFieldWithIssues("predialPayment")
                    ? null
                    : targetFile("predialPayment", e.target.id)
                }
              >
                Seleccionar pago de predial (opcional)
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white mb-2">
                Pago de predial cargado
                <button
                  className="delete is-small"
                  onClick={() =>
                    isFieldWithIssues("predialPayment")
                      ? null
                      : deleteFile("owner", 0)
                  }
                ></button>
              </div>
            )}
          </div>
          <input
            disabled={isFieldWithIssues("ownerFile")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="owner"
            id="owner"
          />
          <input
            disabled={isFieldWithIssues("houseDeeds")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="houseDeeds"
            id="houseDeeds"
          />
          <input
            disabled={isFieldWithIssues("registrationTicket")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="registrationTicket"
            id="registrationTicket"
          />
          <input
            disabled={isFieldWithIssues("predialPayment")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="predialPayment"
            id="predialPayment"
          />
        </div>
        {guarantorData.isBusiness && (
          <>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file-alt fa-lg"></i>
                </span>
                <br />
                <b>Acta Constitutiva (Con boleta registral)</b>
                <br />
                {files.act.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      isFieldWithIssues("actFile")
                        ? null
                        : targetFile("act", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-success has-text-white">
                    Acta Constitutiva
                    <button
                      className="delete is-small"
                      onClick={() =>
                        isFieldWithIssues("actFile")
                          ? null
                          : deleteFile("act", 0)
                      }
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("actFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="act"
                  id="act"
                />
              </div>
            </div>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file-alt fa-lg"></i>
                </span>
                <br />
                <b>Cédula Fiscal</b>
                <br />
                {files.fiscal.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      isFieldWithIssues("fiscalFile")
                        ? null
                        : targetFile("fiscal", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-primary has-text-white">
                    Cédula Fiscal
                    <button
                      className="delete is-small"
                      onClick={() =>
                        isFieldWithIssues("fiscalFile")
                          ? null
                          : deleteFile("fiscal", 0)
                      }
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("fiscalFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="fiscal"
                  id="fiscal"
                />
              </div>
            </div>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file fa-lg"></i>
                </span>
                <br />
                <b>Poder del representante legal (Opcional)</b>
                <br />
                {files.power.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      isFieldWithIssues("powerFile")
                        ? null
                        : targetFile("power", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-primary has-text-white">
                    Poder del representante
                    <button
                      className="delete is-small"
                      onClick={() =>
                        isFieldWithIssues("powerFile")
                          ? null
                          : deleteFile("power", 0)
                      }
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("powerFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="power"
                  id="power"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="navbar">
        <div className="navbar-start"></div>
        <div className="navbar-end">
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={() => {
              next();
            }}
          >
            <span>Pagar y enviar</span>
            <span className="icon">
              <i className="fas fa-check-circle"></i>
            </span>
          </button>
        </div>
      </div>

      <Dialog isActive={showHelpModal} close={() => setShowHelpModal(false)}>
        <div className="notification is-white has-text-centered overflow-auto">
          <p className="title is-2 has-text-centered">
            Ejemplo de boleta registral
          </p>

          <img src={boleta} />
        </div>
      </Dialog>
    </div>
  );
};
