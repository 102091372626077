import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Input } from "../../components/Form";
import { CreateCustomerIfNotExist, Pay, SaveBillingDetails } from "../../services/Renter";
import AuthContext from "../../services/authService/context";

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement onChange={onChange} />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`button is-fullwidth ${error ? "is-danger" : "is-success"}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Procesando..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CollapsibleItem = styled.div`
  transition: all 0.2s ease;
  cursor: pointer;
  border: 2px solid transparent;

  .content {
    padding: 3px;
    transition: all 0.3s ease;
    overflow: hidden;
    height: 0px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    border-color: #1d4c52;
  }

  &.is-active {
    background: rgba(29, 76, 82, 0.1);
    border-color: #1d4c52;
    .content {
      height: ${({ height }) => height};
    }

    .header {
      color: #1d4c52 !important;
    }
  }
`;

export const Checkout = (props) => {
  const { currentUser } = useContext(AuthContext);

  const [activeElement, setActiveElement] = useState("card");
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [type, setType] = useState("REGULAR");
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    name: currentUser.firstName + " " + currentUser.lastName,
    email: currentUser.email,
  });

  const [taxDetails, setTaxDetails] = useState({
    use: "",
    name: "",
    rfc: "",
    address: "",
  });
  const [needInvoice, setNeedInvoice] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // save billing details

    if(needInvoice) {
      const res = await SaveBillingDetails(taxDetails);
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    let {
      data: { renter },
    } = await CreateCustomerIfNotExist({ ...billingDetails });

    let card = elements.getElement(CardElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: billingDetails,
    });
    //const paymentMethod = await AttachPayMentAndCharge({
    //  customer: renter.stripeId,
    //  payment: payload.paymentMethod.id
    //})
    try {
      const result = await stripe.createToken(card);
      const {
        data: { paymentDoc, procedure },
      } = await Pay({
        token: result.token,
        customer: renter.stripeId,
        type,
      });

      setProcessing(false);

      if (payload.error) {
        setError(payload.error);
      }

      if (paymentDoc.status === "succeeded") {
        setPaymentMethod(payload.paymentMethod);
      }

      localStorage.setItem("procedure", JSON.stringify(procedure));

      window.location = "/panel/mi-investigacion";
    } catch (error) {
      const { response } = error;
      console.log(
        "Log at 👉  ~ file: index.jsx ~ line 144 ~ handleSubmit ~ error",
        response.data
      );
      setError({
        message: response.data,
      });
    }
  };

  return (
    <div>
      <div>
        <div>
          <p className="title is-3 has-text-centered">Pagar</p>

          {paymentMethod ? (
            ""
          ) : (
            <div>
              <p className="subtitle is-5 has-text-centered">
                Paga tu investigación para enviarla, y nuestros ejecutivos harán
                el resto.
              </p>
              <p className="subtitle is-5 has-text-centered">
                <b>Selecciona una investigación para el pago:</b>
              </p>
              <hr />
            </div>
          )}

          {paymentMethod ? (
            <div className="has-text-centered">
              <i className="fas fa-check-circle is-size-1 has-text-success"></i>
              <br />
              <br />
              <p className="subtitle is-3">Pago realizado correctamente</p>
            </div>
          ) : (
            <div>
              <CollapsibleItem
                height="auto"
                className={`notification normal ${
                  activeElement === "card" ? "is-active" : ""
                }`}
                onClick={() => setActiveElement("card")}
              >
                <div className="header mb-3">
                  <span className="has-text-weight-bold is-size-5">
                    Investigación
                  </span>

                  <span className="icon is-size-5">
                    <i className="fas fa-credit-card"></i>
                  </span>
                </div>
                <div className="content">
                  <div className="columns">
                    <button
                      onClick={() => setType("REGULAR")}
                      className={`button column is-6 is-full-mobile ${
                        type == "REGULAR" ? "is-primary" : "is-white"
                      }`}
                    >
                      Regular $749.00MXN
                    </button>
                    <button
                      onClick={() => setType("CLG")}
                      className={`button column is-6 is-full-mobile ${
                        type == "CLG" ? "is-primary" : "is-white"
                      }`}
                      title="Certificado con libertad de gravamen"
                    >
                      Con CLG $1,549.00MXN
                    </button>
                  </div>
                  <form className="form mt-2" onSubmit={handleSubmit}>
                    <fieldset>
                      <Input
                        label="Nombre"
                        type="text"
                        placeholder="Nombre"
                        inputClass="is-transparent is-borderless is-shadowless"
                        value={billingDetails.name}
                        onChange={({ target: { value } }) =>
                          setBillingDetails({ ...billingDetails, name: value })
                        }
                        error="Este campo es obligatorio"
                      />

                      <Input
                        label="Correo electrónico"
                        type="email"
                        placeholder="email@email.com"
                        inputClass="is-transparent is-borderless is-shadowless"
                        value={billingDetails.email}
                        onChange={({ target: { value } }) =>
                          setBillingDetails({ ...billingDetails, email: value })
                        }
                        error="Este campo es obligatorio"
                      />
                    </fieldset>
                    <fieldset>
                      <label className="checkbox mt-5">
                        <input
                          type="checkbox"
                          name="setNeedInvoice"
                          id="setNeedInvoice"
                          checked={needInvoice}
                          onChange={(e) => setNeedInvoice(e.target.checked)}
                        />
                        &nbsp;¿Requiere factura?
                      </label>
                    </fieldset>
                    {needInvoice && (
                      <>
                        <br />
                        <label className="label">Datos de facturación</label>
                        <Input
                          label="Uso"
                          type="text"
                          placeholder="Uso"
                          inputClass="is-transparent is-borderless is-shadowless"
                          value={taxDetails.use}
                          onChange={({ target: { value } }) =>
                            setTaxDetails({ ...taxDetails, use: value })
                          }
                          error="Este campo es obligatorio"
                        />
                        <Input
                          label="Nombre"
                          type="text"
                          placeholder="Nombre"
                          inputClass="is-transparent is-borderless is-shadowless"
                          value={taxDetails.name}
                          onChange={({ target: { value } }) =>
                            setTaxDetails({ ...taxDetails, name: value })
                          }
                          error="Este campo es obligatorio"
                        />
                        <Input
                          label="RFC"
                          type="text"
                          placeholder="RFC"
                          inputClass="is-transparent is-borderless is-shadowless"
                          value={taxDetails.rfc}
                          onChange={({ target: { value } }) =>
                            setTaxDetails({ ...taxDetails, rfc: value })
                          }
                          error="Este campo es obligatorio"
                        />
                        <Input
                          label="Dirección"
                          type="text"
                          placeholder="Dirección"
                          inputClass="is-transparent is-borderless is-shadowless"
                          value={taxDetails.address}
                          onChange={({ target: { value } }) =>
                            setTaxDetails({
                              ...taxDetails,
                              address: value,
                            })
                          }
                          error="Este campo es obligatorio"
                        />
                      </>
                    )}
                    <hr />
                    <fieldset>
                      <label className="label">Datos de la tarjeta</label>
                      <CardField
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </fieldset>
                    <hr />
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                    <SubmitButton
                      processing={processing}
                      error={error}
                      disabled={!stripe}
                    >
                      Pagar ${type == "REGULAR" ? "749.00" : "1,549.00"}MXN
                    </SubmitButton>
                  </form>
                </div>
              </CollapsibleItem>
              {/*< CollapsibleItem height="200px" className={`notification normal ${ activeElement === 'oxxo'?'is-active':'' }`} onClick={() => setActiveElement('oxxo')}>              
                  <div className="header">
                    <span className="has-text-weight-bold is-size-5">
                      Pago en efectivo en OXXO
                    </span>

                    <span className="icon is-size-5">
                      <i className="fas fa-money-bill-wave"></i>
                    </span>
                  </div>

                  <div className="content">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt maxime quo minus ratione eveniet libero adipisci, et alias fugit voluptatem aliquid porro! Libero, corporis itaque? Autem mollitia omnis labore iure.
                  </div>
                </CollapsibleItem> */}
            </div>
          )}

          <br />
          {!paymentMethod ? (
            ""
          ) : (
            <div className="has-text-centered">
              <NavLink
                className="button is-primary is-medium"
                to="/panel/mi-investigacion"
              >
                <span>Continuar</span>
                <span className="icon">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
