import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthUser, setToken, setCurrentUser } from "../../services/User";

export const SignInForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, showError] = useState("");

  const signIn = async (e) => {
    e.preventDefault();
    try {
      let { data } = await AuthUser({
        email: email?.toLowerCase(),
        password,
      });

      setToken(data.token);
      setCurrentUser(data.userData);

      window.location.href = "/";
    } catch (error) {
      let { data } = error.response;

      if (error.response.status === 429) {
        showError(data);
      } else {
        showError("Usuario o contraseña incorrectos.");
      }
    }
  };

  return (
    <div>
      <div className="has-text-centered">
        <img src="/assets/logo.png" alt="Invix" className="logo is-centered" />
      </div>
      <br />
      <p className="title is-3 has-text-centered">Iniciar sesión</p>
      {error !== "" ? (
        <div className="notification is-danger has-text-white normal">
          {error}
        </div>
      ) : null}
      <form onSubmit={signIn}>
        <div className="field">
          <label className="label">Correo electrónico</label>
          <div className="control">
            <input
              className="input is-medium"
              type="email"
              placeholder="ejemplo@ejemplo.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Contraseña</label>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              placeholder="***"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <NavLink
          to="/recuperar-contrasena"
          className="has-text-primary has-text-weight-bold"
        >
          Recuperar contraseña
        </NavLink>
        <hr />
        <button className="button is-primary is-fullwidth" type="submit">
          Aceptar
        </button>
      </form>
      <br />
    </div>
  );
};
