import React from 'react'

export const Footer = () => {
  return (
    <footer class="footer has-background-info">
      <div class="content px-6">
        <div className="columns">
          <div className="column is-4 has-text-white">
            <img src="/assets/logo.png" alt="" width="200px"/>
            <br/>
            <strong className="has-text-white">Invix MX</strong> Todos los derechos reservados <br/>
          </div>
          <div className="column is-4 has-text-white">
            <p>
              Dirección: Guayaquil 2787, colonia Providencia, Guadalajara, Jalisco, código postal 44630.
            </p>
            <p>
              Teléfono: 33 2921 3210
            </p>
            <p>
              Contacto contacto@invix.mx
            </p>
            <p>
              Instagram <a href="https://www.instagram.com/invix.mx/" target="_blank" rel="noopener noreferrer">invix.mx</a>
            </p>
          </div>
          <div className="column is-4">
            <p>
              <a href="/assets/docs/terminos_condiciones.pdf" target="_blank" rel="noopener noreferrer" className='has-text-white'>Términos de uso</a>
            </p>
            <p>
              <a href="/assets/docs/aviso_de_privacidad.pdf" target="_blank" rel="noopener noreferrer" className='has-text-white'>Aviso de privacidad</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
