import React, { useContext, useEffect, useState } from "react";
import { Input } from "../../../../components/Form";
import AuthContext from "../../../../services/authService/context";
import {
  Models,
  UpdateField,
  UploadFile,
  STS,
  states,
  statesNames,
  GetSection,
} from "../../../../services/Renter";
import moment from "moment";
import { isObjectFilled } from "../../../../Utils";
import {
  RenterContext,
  RenterInformationContext,
} from "../../../../services/Renter/context";
import { useQuery } from "../../../../hooks/useQuery";

export const regexCupr =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const initialData = {
  firstName: "",
  secondName: "",
  lastName1: "",
  lastName2: "",
  birthdate: null,
  birthPlace: "",
  rfc: "",
  businessRfc: "",
  curp: "",
  nationality: "",
  maritialStatus: "",
  spouseName: "",
  street: "",
  number: "",
  postalCode: "",
  state: "",
  city: "",
  suburb: "",
  mun: "",
  houseStatus: "propio",
  otherStatus: "",
  rentAmmount: "",
  timeInHouse: "",
  houseOwner: "",
  houseContact: "",
  businessName: "",
  businessNationality: "",
};
export const GeneralInformation = (props) => {
  const query = useQuery();
  const procedure = query.get("procedure");
  const { generalData: defaultData } = props;
  const { completetSection, isFieldWithIssues } = useContext(
    RenterInformationContext
  );

  let date = new Date(defaultData.birthdate);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  const { currentUser } = useContext(AuthContext);
  const {
    renterData: { isBusiness },
    disabled,
  } = useContext(RenterContext);

  const [generalData, setGeneralData] = useState({
    ...initialData,
    ...defaultData,
    birthdate: moment(date).format("yyyy-MM-DD"),
  });

  const [files, setFiles] = useState({
    ine: generalData.ineFile || [],
    dom: generalData.domFile || [],
    entry: generalData.entryFile || [],
    act: generalData.actFile || [],
    fiscal: generalData.fiscalFile || [],
    power: generalData.powerFile || [],
  });

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    async function getData() {
      let { data } = await GetSection({
        section: Models.generalData,
      });

      setFiles({
        ine: data.ineFile || [],
        dom: data.domFile || [],
        entry: data.entryFile || [],
        act: data.actFile || [],
        fiscal: data.fiscalFile || [],
        power: data.powerFile || [],
      });
      setGeneralData(data);
    }
    getData();
  }, []);

  const updateField = async (field, value) => {
    let {
      notes,
      otherStatus,
      rentAmmount,
      businessName,
      businessNationality,
      rfc,
      businessRfc,
      spouseName,
      secondName,
      houseOwner,
      houseContact,
      legalHistoryQualification,
      creditHistoryQualification,
      legalHistoryNotes,
      creditHistoryNotes,
      ...rest
    } = generalData;
    setLoading(field);

    let completed = isObjectFilled(rest);

    await UpdateField({
      modelName: Models.generalData,
      update: {
        [field]: value,
        completed,
      },
    });

    setLoading("");
  };

  const next = async () => {
    setShowErrors(true);
    let {
      notes,
      otherStatus,
      rentAmmount,
      spouseName,
      rfc,
      businessRfc,
      businessName,
      businessNationality,
      secondName,
      houseOwner,
      houseContact,
      legalHistoryQualification,
      creditHistoryQualification,
      legalHistoryNotes,
      creditHistoryNotes,
      ...rest
    } = generalData;
    let completed = isObjectFilled(rest);

    if (completed) {
      await UpdateField({
        modelName: Models.generalData,
        update: {
          completed,
        },
      });
      completetSection("generalData", generalData);
      const procedureParam = procedure ? `?procedure=${procedure._id}` : "";
      return props.history.push(
        `/panel/informacion/actividad-economica${procedureParam}`
      );
    }
  };

  const targetFile = (file, id) => {
    document.getElementById(file).click();
  };

  const setFile = async (e) => {
    let filesSelected = e.target.files;
    let data = new FormData();
    for (let i = 0; i < filesSelected.length; i++) {
      data.append(filesSelected[i].name, filesSelected[i]);
    }
    data.append("fileName", e.target.id);
    data.append("modelName", Models.generalData);
    let {
      data: { urls },
    } = await UploadFile(data);
    setFiles({ ...files, [e.target.id]: [...urls] });
  };

  const deleteFile = (file, indexFile) => {
    let filesUpdate = files[file];
    filesUpdate.splice(indexFile, 1);
    setFiles({ ...files, [file]: [...filesUpdate] });
  };

  const updateIsBusiness = async (hasIsBusiness) => {
    await UpdateField({
      modelName: Models.renter,
      update: {
        isBusiness: hasIsBusiness !== null ? hasIsBusiness : isBusiness,
      },
    });
    localStorage.setItem("isBusiness", isBusiness);
    window.location.reload();
  };

  return (
    <div className="info-form">
      <div className="navbar">
        <div className="navbar-start">
          <div>
            <p className="title is-4 has-text-primary">Información general</p>
            <p className="subtitle is-5">
              Los datos se guardan automáticamente
            </p>
          </div>
        </div>
        <div className="navbar-end">
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={() => {
              next();
            }}
          >
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
      <br />
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          <button
            className="button is-light is-info"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>
              {" "}
              Respondiendo como:{" "}
              <b>{isBusiness ? "Persona Moral" : "Persona Física"}</b>
            </span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div
          className="dropdown-menu is-fullwidth"
          id="dropdown-menu"
          role="menu"
        >
          <div className="dropdown-content">
            <a
              href="#"
              className="dropdown-item"
              onClick={() => {
                updateIsBusiness(!isBusiness);
              }}
            >
              Cambiar a: &nbsp;
              <b>{isBusiness ? "Persona Física" : "Persona Moral"}</b>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="columns is-multiline">
        {isBusiness && (
          <>
            <div className="column is-6">
              <Input
                disabled={isFieldWithIssues("businessName")}
                label="Razón Social"
                type="text"
                placeholder="Razón Social"
                value={generalData.businessName}
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, businessName: value })
                }
                onBlur={(e) =>
                  updateField("businessName", generalData.businessName)
                }
              />
            </div>
            <div className="column is-2">
              <Input
                disabled={isFieldWithIssues("businessRfc")}
                isLoading={loading === "businessRfc"}
                label={"R.F.C"}
                type="text"
                maxLength={"15"}
                placeholder="RFC DE LA EMPRESA"
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, businessRfc: value })
                }
                value={generalData.businessRfc}
                onBlur={(e) =>
                  updateField("businessRfc", generalData.businessRfc)
                }
              />
            </div>
            <div className="column is-6">
              <div className="field">
                <label className="label">Nacionalidad de la empresa</label>
                <div className="control">
                  <div
                    className={`select is-fullwidth ${
                      loading === "businessNationality" ? "is-loading" : ""
                    }`}
                  >
                    <select
                      value={generalData.businessNationality}
                      onChange={({ target: { value } }) => {
                        setGeneralData({
                          ...generalData,
                          businessNationality: value,
                        });
                        updateField("businessNationality", value);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      <option value="mexicana">Mexicana</option>
                      <option value="extranjera">Extranjera</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isBusiness && (
          <div className="column is-12">
            <p className="is-size-5 has-text-primary has-text-weight-bold	">
              Datos del representante legal
            </p>
          </div>
        )}
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("lastName1")}
            label="Apellido Paterno"
            type="text"
            placeholder="Apellido Paterno"
            value={generalData.lastName1}
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, lastName1: value })
            }
            // defaultValue={currentUser.firstName + ' ' + currentUser.lastName}
            error="Este campo es obligatorio"
            onBlur={(e) => updateField("lastName1", generalData.lastName1)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("lastName2")}
            label="Apellido Materno"
            type="text"
            placeholder="Apellido Materno"
            value={generalData.lastName2}
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, lastName2: value })
            }
            error="Este campo es obligatorio"
            onBlur={(e) => updateField("lastName2", generalData.lastName2)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("firstName")}
            label="Primer Nombre"
            type="text"
            placeholder="Primer Nombre"
            value={generalData.firstName}
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, firstName: value })
            }
            error="Este campo es obligatorio"
            onBlur={(e) => updateField("firstName", generalData.firstName)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("secondName")}
            label="Segundo Nombre"
            type="text"
            placeholder="Segundo Nombre"
            value={generalData.secondName}
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, secondName: value })
            }
            error="Este campo es obligatorio"
            onBlur={(e) => updateField("secondName", generalData.secondName)}
          />
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">Estado civil</label>
            <div className="control">
              <div
                className={`select is-fullwidth ${
                  loading === "maritialStatus" ? "is-loading" : ""
                }`}
              >
                <select
                  disabled={isFieldWithIssues("maritialStatus")}
                  value={generalData.maritialStatus}
                  onChange={({ target: { value } }) => {
                    setGeneralData({ ...generalData, maritialStatus: value });
                    updateField("maritialStatus", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  <option value="soltero">Soltero(a)</option>
                  <option value="casado">Casado(a)</option>
                  <option value="unionlibre">Union libre</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {["casado", "unionlibre"].indexOf(generalData.maritialStatus) !== -1 ? (
          <>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("spouseName")}
                isLoading={loading === "spouseName"}
                label="Nombre del cónyuge"
                type="text"
                placeholder="Nombre completo"
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, spouseName: value })
                }
                value={generalData.spouseName}
                error="Este campo es obligatorio"
                showError={generalData.spouseName === "" && showErrors}
                onBlur={(e) =>
                  updateField("spouseName", generalData.spouseName)
                }
              />
            </div>
            <div className="column is-4">
              <Input
                disabled={isFieldWithIssues("spousePhone")}
                isLoading={loading === "spousePhone"}
                label="Teléfono del cónyuge"
                type="tel"
                placeholder="Teléfono completo"
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, spousePhone: value })
                }
                value={generalData.spousePhone}
                error="Este campo es obligatorio"
                showError={generalData.spousePhone === "" && showErrors}
                onBlur={(e) =>
                  updateField("spousePhone", generalData.spousePhone)
                }
              />
            </div>
          </>
        ) : null}
        <div className="column is-2">
          <Input
            disabled={isFieldWithIssues("rfc")}
            isLoading={loading === "rfc"}
            label={"R.F.C (Opcional)"}
            type="text"
            maxLength={"15"}
            placeholder="Con homoclave"
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, rfc: value })
            }
            value={generalData.rfc}
            onBlur={(e) => updateField("rfc", generalData.rfc)}
          />
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">Nacionalidad</label>
            <div className="control">
              <div
                className={`select is-fullwidth ${
                  loading === "nationality" ? "is-loading" : ""
                }`}
              >
                <select
                  disabled={isFieldWithIssues("nationality")}
                  value={generalData.nationality}
                  onChange={({ target: { value } }) => {
                    setGeneralData({ ...generalData, nationality: value });
                    updateField("nationality", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  <option value="mexicana">Mexicana</option>
                  <option value="extranjera">Extranjera</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {generalData.nationality === "mexicana" ? (
          <div className="column is-6">
            <Input
              disabled={isFieldWithIssues("curp")}
              isLoading={loading === "curp"}
              label="C.U.R.P."
              type="text"
              placeholder="Se validará con renapo"
              onChange={({ target: { value } }) => {
                setGeneralData({ ...generalData, curp: value });
              }}
              value={generalData.curp}
              helper={
                <a
                  href="https://www.gob.mx/curp/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  ¿No sabes tu CURP?
                </a>
              }
              error="Formato incorrecto de CURP"
              showError={generalData.curp === "" && showErrors}
              onBlur={(e) => {
                updateField("curp", generalData.curp);
                let state = generalData.curp.substr(11, 2);

                /*let year = generalData.curp.substr(4, 2);
                  let month = generalData.curp.substr(4, 2);
                  let day = generalData.curp.substr(4, 2);*/
                if (STS.indexOf(state) !== -1) {
                  setGeneralData({
                    ...generalData,
                    birthPlace: states[state].toUpperCase(),
                  });
                  updateField("birthPlace", states[state].toUpperCase());
                }
              }}
            />
          </div>
        ) : null}
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("birthdate")}
            isLoading={loading === "birthdate"}
            label="Fecha de nacimiento"
            type="date"
            placeholder="Fecha de nacimiento"
            onChange={({ target: { value } }) => {
              console.log(value);
              setGeneralData({ ...generalData, birthdate: value });
            }}
            value={generalData.birthdate}
            error="Este campo es obligatorio"
            showError={generalData.birthdate === "" && showErrors}
            onBlur={(e) => updateField("birthdate", generalData.birthdate)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={isFieldWithIssues("birthPlace")}
            isLoading={loading === "birthPlace"}
            label="Lugar de nacimiento"
            type="text"
            placeholder="Ciudad y estado"
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, birthPlace: value })
            }
            value={generalData.birthPlace}
            error="Este campo es obligatorio"
            showError={generalData.birthPlace === "" && showErrors}
            onBlur={(e) => updateField("birthPlace", generalData.birthPlace)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("email")}
            label="Correo electrónico"
            type="text"
            placeholder="ejemplo@gmail.com"
            readOnly
            value={currentUser.email}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("phone")}
            isLoading={loading === "phone"}
            label="Teléfono"
            type="tel"
            placeholder="Teléfono (10 dígitos)"
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, phone: value })
            }
            value={generalData.phone}
            error="Este campo es obligatorio"
            showError={generalData.phone === "" && showErrors}
            onBlur={(e) => updateField("phone", generalData.phone)}
          />
        </div>
      </div>
      <p className="is-uppercase has-text-weight-bold	 has-text-info">
        Domicilio:
      </p>
      <br />
      <div className="columns is-multiline">
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("street")}
            isLoading={loading === "street"}
            label="Calle"
            type="text"
            placeholder="Nombre de la calle "
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, street: value })
            }
            value={generalData.street}
            error="Este campo es obligatorio"
            showError={generalData.street === "" && showErrors}
            onBlur={(e) => updateField("street", generalData.street)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("number")}
            isLoading={loading === "number"}
            label="Número"
            type="text"
            placeholder="Número y/o interior"
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, number: value })
            }
            value={generalData.number}
            error="Este campo es obligatorio"
            showError={generalData.number === "" && showErrors}
            onBlur={(e) => updateField("number", generalData.number)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("postalCode")}
            isLoading={loading === "postalCode"}
            label="Código postal"
            type="text"
            placeholder=""
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, postalCode: value })
            }
            value={generalData.postalCode}
            error="Este campo es obligatorio"
            showError={generalData.postalCode === "" && showErrors}
            onBlur={(e) => updateField("postalCode", generalData.postalCode)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("city")}
            isLoading={loading === "city"}
            label="Ciudad"
            type="text"
            placeholder=""
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, city: value.toUpperCase() })
            }
            value={generalData.city}
            error="Este campo es obligatorio"
            showError={generalData.city === "" && showErrors}
            onBlur={(e) => updateField("city", generalData.city)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("suburb")}
            isLoading={loading === "suburb"}
            label="Colonia"
            type="text"
            placeholder=""
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, suburb: value.toUpperCase() })
            }
            value={generalData.suburb}
            error="Este campo es obligatorio"
            showError={generalData.suburb === "" && showErrors}
            onBlur={(e) => updateField("suburb", generalData.suburb)}
          />
        </div>
        <div className="column is-4">
          <Input
            disabled={isFieldWithIssues("mun")}
            isLoading={loading === "mun"}
            label="Delegación o municipio"
            type="text"
            placeholder=""
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, mun: value.toUpperCase() })
            }
            value={generalData.mun}
            error="Este campo es obligatorio"
            showError={generalData.mun === "" && showErrors}
            onBlur={(e) => updateField("mun", generalData.mun)}
          />
        </div>
        <div className="column is-4">
          <div className="field">
            <label className="label">Estado</label>
            <div className="control">
              <div
                className={`select is-fullwidth ${
                  loading === "state" ? "is-loading" : ""
                }`}
              >
                <select
                  disabled={isFieldWithIssues("state")}
                  value={generalData.state}
                  onChange={({ target: { value } }) => {
                    setGeneralData({ ...generalData, state: value });
                    updateField("state", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona un estado
                  </option>
                  {statesNames.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-12">
          <hr className="is-marginless" />
        </div>
        <div className="column is-4">
          <p className="is-size-5 has-text-primary has-text-weight-bold	">
            Su domicilio actual es:
          </p>
          <br />
          <div className="field">
            <div
              className="control"
              value={generalData.houseStatus}
              onChange={({ target: { value } }) => {
                setGeneralData({ ...generalData, houseStatus: value });
                updateField("houseStatus", value);
              }}
            >
              <label
                className="radio is-size-5"
                style={{ marginRight: "30px" }}
              >
                <input
                  disabled={isFieldWithIssues("houseStatus")}
                  type="radio"
                  value="propio"
                  defaultChecked={generalData.houseStatus === "propio"}
                  name="question"
                />
                Propio
              </label>
              <label
                className="radio is-size-5"
                style={{ marginRight: "30px" }}
              >
                <input
                  disabled={isFieldWithIssues("houseStatus")}
                  type="radio"
                  value="rentado"
                  defaultChecked={generalData.houseStatus === "rentado"}
                  name="question"
                />
                Rentado
              </label>
              <label
                className="radio is-size-5"
                style={{ marginRight: "30px" }}
              >
                <input
                  disabled={isFieldWithIssues("houseStatus")}
                  type="radio"
                  value="otro"
                  defaultChecked={generalData.houseStatus === "otro"}
                  name="question"
                />
                Otro
              </label>
            </div>
          </div>
        </div>
        <div className="column is-3">
          {generalData.houseStatus === "rentado" && (
            <Input
              disabled={isFieldWithIssues("rentAmmount")}
              isLoading={loading === "rentAmmount"}
              label="Monto de renta"
              type="number"
              placeholder=""
              onChange={({ target: { value } }) =>
                setGeneralData({ ...generalData, rentAmmount: value })
              }
              value={generalData.rentAmmount}
              error="Este campo es obligatorio"
              showError={generalData.rentAmmount === "" && showErrors}
              onBlur={(e) =>
                updateField("rentAmmount", generalData.rentAmmount)
              }
              iconLeft={
                <span className="icon is-small is-left">
                  <i className="fas fa-dollar-sign has-text-black"></i>
                </span>
              }
            />
          )}

          {generalData.houseStatus === "otro" && (
            <Input
              disabled={isFieldWithIssues("otherStatus")}
              isLoading={loading === "otherStatus"}
              label="Especifique"
              type="text"
              placeholder=""
              onChange={({ target: { value } }) =>
                setGeneralData({ ...generalData, otherStatus: value })
              }
              value={generalData.otherStatus}
              error="Este campo es obligatorio"
              showError={generalData.otherStatus === "" && showErrors}
              onBlur={(e) =>
                updateField("otherStatus", generalData.otherStatus)
              }
            />
          )}
        </div>
        <div className="column is-12">
          <hr className="is-marginless" />
        </div>
        <div className="column is-6">
          <Input
            disabled={isFieldWithIssues("timeInHouse")}
            isLoading={loading === "timeInHouse"}
            label="Antigüedad en el domicilio"
            type="text"
            placeholder=""
            onChange={({ target: { value } }) =>
              setGeneralData({ ...generalData, timeInHouse: value })
            }
            value={generalData.timeInHouse}
            error="Este campo es obligatorio"
            showError={generalData.timeInHouse === "" && showErrors}
            onBlur={(e) => updateField("timeInHouse", generalData.timeInHouse)}
          />
        </div>

        {generalData.houseStatus !== "propio" && (
          <>
            <div className="column is-6">
              <Input
                disabled={isFieldWithIssues("houseOwner")}
                isLoading={loading === "houseOwner"}
                label="Nombre del dueño"
                type="text"
                placeholder="Nombre y/o apellido"
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, houseOwner: value })
                }
                value={generalData.houseOwner}
                error="Este campo es obligatorio"
                showError={
                  generalData.houseStatus != "rentado" &&
                  generalData.houseOwner === "" &&
                  showErrors
                }
                onBlur={(e) =>
                  updateField("houseOwner", generalData.houseOwner)
                }
              />
            </div>

            <div className="column is-6">
              <Input
                disabled={isFieldWithIssues("houseContact")}
                isLoading={loading === "houseContact"}
                label="Contacto del dueño"
                type="tel"
                placeholder="Télefono"
                onChange={({ target: { value } }) =>
                  setGeneralData({ ...generalData, houseContact: value })
                }
                value={generalData.houseContact}
                error="Este campo es obligatorio"
                showError={
                  generalData.houseStatus != "rentado" &&
                  generalData.houseContact === "" &&
                  showErrors
                }
                onBlur={(e) =>
                  updateField("houseContact", generalData.houseContact)
                }
              />
            </div>
          </>
        )}
      </div>
      <hr />
      <p className="is-size-5 has-text-primary has-text-weight-bold	">
        Documentos:
      </p>
      <p className="is-size-6 has-text-primary">
        Asegurate que los documentos aparezcan completos y con buena calidad.
      </p>
      <hr />
      <div className="columns is-multiline">
        {/* <div className="column is-4">
          <div className="notification has-text-centered">  
            <span className="icon">
              <i className="fas fa-money-bill-wave-alt fa-lg"></i>
            </span>    
            <br/>
            <b>
              3 últimos comprobantes de ingreso.
              {
                isBusiness && <>
                <br/>
                  Declaraciones de ISR o estados de cuenta
                </>
              }
            </b>
            <br/>
            {
              files.entry.length === 0 ? <button className="button is-primary is-rounded" onClick={ (e) => targetFile('entry', e.target.id) }>
                Selecciona un archivo .pdf
              </button> : files.entry.map((f, i) => (
                <div key={i} className="tag is-medium is-rounded is-primary has-text-white" style={{margin: '5px'}}>
                  Documento: { i + 1 }
                  <button className="delete is-small" onClick={() => deleteFile('entry', i)}></button>
                </div>
              ))
            }
            <input
              disabled={disabled}
              onChange={setFile}
              multiple={true}
              className="is-hidden"
              type="file"
              name="entry"
              id="entry"
            />
          </div>
        </div> */}
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fas fa-id-card fa-lg"></i>
            </span>
            <br />
            <b>
              Identificación oficial
              {isBusiness && (
                <>
                  <br />
                  Del representante legal
                </>
              )}
            </b>
            <br />
            {files.ine.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) => targetFile("ine", e.target.id)}
              >
                Selecciona un archivo .pdf
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white">
                INE Cargada
                <button
                  className="delete is-small"
                  onClick={() => deleteFile("ine", 0)}
                ></button>
              </div>
            )}
          </div>
          <input
            disabled={isFieldWithIssues("ineFile")}
            onChange={setFile}
            multiple={true}
            className="is-hidden"
            type="file"
            name="ine"
            id="ine"
          />
        </div>
        <div className="column is-4">
          <div className="notification has-text-centered">
            <span className="icon">
              <i className="fa fa-home fa-lg"></i>
            </span>
            <br />
            <b>Comprobante de domicilio</b>
            <br />
            {files.dom.length === 0 ? (
              <button
                className="button is-primary is-rounded"
                onClick={(e) => targetFile("dom", e.target.id)}
              >
                Selecciona un archivo .pdf
              </button>
            ) : (
              <div className="tag is-medium is-rounded is-success has-text-white">
                Comprobante Cargado
                <button
                  className="delete is-small"
                  onClick={() => (disabled ? null : deleteFile("dom", 0))}
                ></button>
              </div>
            )}
            <input
              disabled={isFieldWithIssues("domFile")}
              onChange={setFile}
              multiple={true}
              className="is-hidden"
              type="file"
              name="dom"
              id="dom"
            />
          </div>
        </div>
        {isBusiness && (
          <>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file-alt fa-lg"></i>
                </span>
                <br />
                <b>Acta Constitutiva (Con boleta registral)</b>
                <br />
                {files.act.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      disabled ? null : targetFile("act", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-success has-text-white">
                    Acta Constitutiva Cargada
                    <button
                      className="delete is-small"
                      onClick={() => (disabled ? null : deleteFile("act", 0))}
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("actFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="act"
                  id="act"
                />
              </div>
            </div>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file-alt fa-lg"></i>
                </span>
                <br />
                <b>Cédula Fiscal</b>
                <br />
                {files.fiscal.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      disabled ? null : targetFile("fiscal", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-success has-text-white">
                    Cédula Fiscal Cargada
                    <button
                      className="delete is-small"
                      onClick={() =>
                        disabled ? null : deleteFile("fiscal", 0)
                      }
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("fiscalFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="fiscal"
                  id="fiscal"
                />
              </div>
            </div>
            <div className="column is-4">
              <div className="notification has-text-centered">
                <span className="icon">
                  <i className="fa fa-file fa-lg"></i>
                </span>
                <br />
                <b>Poder del representante legal (Opcional)</b>
                <br />
                {files.power.length === 0 ? (
                  <button
                    className="button is-primary is-rounded"
                    onClick={(e) =>
                      disabled ? null : targetFile("power", e.target.id)
                    }
                  >
                    Selecciona un archivo .pdf
                  </button>
                ) : (
                  <div className="tag is-medium is-rounded is-success has-text-white">
                    Carta Poder Cargada
                    <button
                      className="delete is-small"
                      onClick={() => (disabled ? null : deleteFile("power", 0))}
                    ></button>
                  </div>
                )}
                <input
                  disabled={isFieldWithIssues("powerFile")}
                  onChange={setFile}
                  multiple={true}
                  className="is-hidden"
                  type="file"
                  name="power"
                  id="power"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="navbar">
        <div className="navbar-start"></div>
        <div className="navbar-end">
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={() => {
              next();
            }}
          >
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
