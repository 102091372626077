export const isObjectFilled = (object, ignoredFields = ["__v"]) => {
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      let ignoredField = ignoredFields.includes(key);
      if (!ignoredField && (object[key] === "" || object[key] === 0)) {
        console.log("Property empy on object", key, object[key]);
        return false;
      }
    }
  }
  return true;
};

Array.prototype.addIngoredField = function (field) {
  let fieldExist = this.includes(field);

  if (fieldExist) return;

  this.push(field);
};

Array.prototype.removeIngoredField = function (field) {
  let fieldIndex = this.indexOf(field);

  if (fieldIndex !== -1) return;

  this.splice(fieldIndex, 1);
};

export function toTitleCase(str = "") {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
