import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { Padding } from '../../../components/Padding';
import { Panel } from '../../../components/Panel';
import { All } from './All';

export const Procedures = (props) => {
  return (
    <Padding padding="20px 50px" mobilePadding="0px">
      <Panel>
        <p className="title">
          Mi investigación
        </p>
        <hr/>
        <Switch>
          <Route exact path="/panel/mi-investigacion" component={All}/>
        </Switch>
      </Panel>
    </Padding>
  )
}
