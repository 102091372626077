import React, { useContext, useState } from 'react';
import { Input } from '../../../../components/Form';
import { UpdateReference } from '../../../../services/Renter';
import { RenterContext } from '.././../../../services/Renter/context';

const defaultData = {
  name: '',
  job: '',
  relationship: '',
  email: '',
  phone: '',
}


export const ReferenceForm = (props) => {
  const { updateReferenceData, index } = props;
  const [reference, setReference] = useState({ ...defaultData, ...props.reference })
  const [loading, setLoading] = useState()
  const { renterData: { isBusiness }, renterData, disabled } = useContext(RenterContext)

  const updateField = async (field, value) => {
    if (typeof value !== Boolean && !value) return;
    setLoading(field)
    await UpdateReference(props.reference._id, {
      [field]: value,
    })
    updateReferenceData(reference, index)
    setLoading('')
  }

  return (
    <div className="info-form">
      <hr />
      <p className="is-uppercase is-size-5 has-text-primary has-text-weight-bold">
        Referencia {isBusiness ? 'comercial/profesional' : 'personal'} {props.index + 1}
      </p>
      <div className="columns is-multiline">
        <div className="column is-3">
          <Input
            disabled={disabled}
            isLoading={loading === 'name'}
            label={isBusiness ? "Nombre/Razón social" : "Nombre"}
            type="text"
            placeholder='Nombre(s) y apellidos'
            value={reference.name}
            onChange={({ target: { value } }) => setReference({ ...reference, name: value })}
            error="Recuerda llenar este campo"
            showError={reference.name === ""}
            onBlur={(e) => updateField('name', reference.name)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={disabled}
            isLoading={loading === 'job'}
            label={isBusiness ? "Ocupación o giro" : "Ocupación"}
            type="text"
            placeholder='¿A que se dedica?'
            onChange={({ target: { value } }) => setReference({ ...reference, job: value })}
            value={reference.job}
            error="Recuerda llenar este campo"
            showError={reference.job === ""}
            onBlur={(e) => updateField('job', reference.job)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={disabled}
            isLoading={loading === 'relationship'}
            label="Relación"
            type="text"
            placeholder='¿Qué es de ti?'
            onChange={({ target: { value } }) => setReference({ ...reference, relationship: value })}
            value={reference.relationship}
            error="Recuerda llenar este campo"
            showError={reference.relationship === ""}
            onBlur={(e) => updateField('relationship', reference.relationship)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={disabled}
            isLoading={loading === 'email'}
            label="Correo electrónico"
            type="email"
            placeholder='Correo de contacto'
            onChange={({ target: { value } }) => setReference({ ...reference, email: value })}
            value={reference.email}
            onBlur={(e) => updateField('email', reference.email)}
          />
        </div>
        <div className="column is-3">
          <Input
            disabled={disabled}
            isLoading={loading === 'phone'}
            label="Teléfono"
            type="tel"
            maxLength="10"
            placeholder='10 digitos'
            onChange={({ target: { value } }) => setReference({ ...reference, phone: value })}
            value={reference.phone}
            error="Recuerda llenar este campo"
            showError={reference.phone === ""}
            onBlur={(e) => updateField('phone', reference.phone)}
          />
        </div>
      </div>
    </div>

  )
}
