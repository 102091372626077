import { useState } from "react";

export const GuarantorData = ({ guarantorData, renter, openFile }) => {
  const [open, setOpen] = useState(false);
  const { isBusiness } = guarantorData;

  return (
    <div class="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Información del fiador
      </p>
      {open && (
        <div>
          {guarantorData?.notes && (
            <div className="notification">
              Notas de nuestro agente: <br />
              <b>{guarantorData?.notes}</b>
            </div>
          )}
          <p className="has-text-weight-bold is-size-5">
            Documentación proporcionada:
          </p>
          <div className="buttons">
            <button
              className="button is-light is-primary"
              onClick={() => openFile(guarantorData.ineFile)}
            >
              INE
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(guarantorData.curpFile)}
            >
              CURP
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(guarantorData.domFile)}
            >
              Comprobante de domicilio
            </button>
            {isBusiness && (
              <>
                <button
                  className="button is-light is-primary"
                  onClick={() => openFile(guarantorData.actFile)}
                >
                  Acta constitutiva (Con boleta registral)
                </button>
                <button
                  className="button is-light is-primary"
                  onClick={() => openFile(guarantorData.fiscalFile)}
                >
                  Cédula Fiscal
                </button>
                {guarantorData.powerFile?.map((f, i) => (
                  <button
                  className="button is-light is-primary"
                  onClick={() => openFile([f])}
                >
                  Carta Poder {i + 1 }
                </button>
                ))}
                
              </>
            )}
          </div>
          <p className="has-text-weight-bold is-size-5">
            Documentación revisada:
          </p>
          <div className="buttons">
            <button
              className="button is-light is-primary"
              onClick={() => openFile(guarantorData.ineValidationFile)}
            >
              Validación INE
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(guarantorData.curpValidationFile)}
            >
              Validación CURP
            </button>
          </div>
          {isBusiness ? (
            <>
              <p className="has-text-weight-bold">Datos de la empresa</p>
              <br />
              <p className="my-1 is-size-5">
                Razón social:&nbsp;
                <b className="has-text-primary">{guarantorData.businessName}</b>
              </p>
              <p className="my-1 is-size-5">
                RFC:&nbsp;
                <b className="has-text-primary">{guarantorData.businessRFC}</b>
              </p>
              <p className="my-1 is-size-5">
                Dirección:&nbsp;
                <b className="has-text-primary">
                  {guarantorData.businessAddress}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Nacionalidad:&nbsp;
                <b className="has-text-primary">
                  {guarantorData.businessNationality}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Teléfono:&nbsp;
                <b className="has-text-primary">
                  {guarantorData.businessPhone}
                </b>
              </p>
              <p className="has-text-weight-bold">
                Datos del representante legal
              </p>
              <br />
              <p className="my-1 is-size-5">
                Nombre (s):&nbsp;
                <b className="has-text-primary">{guarantorData.name}</b>
              </p>
              <p className="my-1 is-size-5">
                Nacionalidad:&nbsp;
                <b className="has-text-primary">{guarantorData.nationality}</b>
              </p>
              <p className="my-1 is-size-5">
                CURP/ID:&nbsp;
                <b className="has-text-primary">{guarantorData.curp}</b>
              </p>
              <p className="my-1 is-size-5">
                RFC:&nbsp;
                <b className="has-text-primary">{guarantorData.rfc}</b>
              </p>
              <p className="my-1 is-size-5">
                Fecha de nacimiento:&nbsp;
                <b className="has-text-primary">{guarantorData.birthdate}</b>
              </p>
              <p className="my-1 is-size-5">
                Lugar de nacimiento:&nbsp;
                <b className="has-text-primary">{guarantorData.birthPlace}</b>
              </p>
              <p className="my-1 is-size-5">
                Domicilio:&nbsp;
                <b className="has-text-primary">{guarantorData.address}</b>
              </p>
              <p className="my-1 is-size-5">
                Teléfono:&nbsp;
                <b className="has-text-primary">{guarantorData.phone}</b>
              </p>
            </>
          ) : (
            <>
              <p className="my-1 is-size-5">
                Nombre (s):&nbsp;
                <b className="has-text-primary">{guarantorData.name}</b>
              </p>
              <p className="my-1 is-size-5">
                Nacionalidad:&nbsp;
                <b className="has-text-primary">{guarantorData.nationality}</b>
              </p>
              <p className="my-1 is-size-5">
                CURP/ID:&nbsp;
                <b className="has-text-primary">{guarantorData.curp}</b>
              </p>
              <p className="my-1 is-size-5">
                RFC:&nbsp;
                <b className="has-text-primary">{guarantorData.rfc}</b>
              </p>
              <p className="my-1 is-size-5">
                Fecha de nacimiento:&nbsp;
                <b className="has-text-primary">{guarantorData.birthdate}</b>
              </p>
              <p className="my-1 is-size-5">
                Lugar de nacimiento:&nbsp;
                <b className="has-text-primary">{guarantorData.birthPlace}</b>
              </p>
              <p className="my-1 is-size-5">
                Domicilio:&nbsp;
                <b className="has-text-primary">{guarantorData.address}</b>
              </p>
              <p className="my-1 is-size-5">
                Teléfono:&nbsp;
                <b className="has-text-primary">{guarantorData.phone}</b>
              </p>
              <p className="my-1 is-size-5">
                Correo electrónico:&nbsp;
                <b className="has-text-primary">{guarantorData.email}</b>
              </p>
              <p className="my-1 is-size-5">
                Estado civil:&nbsp;
                <b className="has-text-primary">
                  {guarantorData.maritialStatus}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Conyuge:&nbsp;
                <b className="has-text-primary">{guarantorData.spouseName}</b>
              </p>
              <p className="my-1 is-size-5">
                Teléfono del conyuge:&nbsp;
                <b className="has-text-primary">{guarantorData.spousePhone}</b>
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
