import React from "react";

export const NotFound = () => {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-centered">
        <div className="has-text-centered">
          <p className="title is-2">Páigna no encontrada</p>
          <p className="subtitle is-4">La página solicitada no existe.</p>
          {/* <img src="/assets/images/not-found.svg" alt="Página no encontrada" width="300"/>    */}
          <h1
            style={{
              fontSize: "64px",
              fontWeight: "bold",
            }}
          >
            404
          </h1>
          <br />
          <br />
          <button
            className="button is-primary is-medium"
            onClick={() => window.history.back()}
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};
