import React, { useEffect, useState } from 'react';
import { GetRenterProcedures } from '../../../../services/Renter';
import { Loader } from '../../../../components/Loader';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/es';

const statusColors = [
  { label: 'Enviado', color: 'is-info has-text-white', descripcion: 'Tu información se ha enviado correctamente, tu investigación ha iniciado.'},
  { label: 'En proceso', color: 'is-primary has-text-white', descripcion: 'Tu trámite está siendo procesado por un agente.'},
  { label: 'Revisión requerida', color: 'is-warning', descripcion: 'Algunos dato o información proporcionada no coincide y necesita una revisión.'},
  { label: 'Terminado', color: 'is-success has-text-white', descripcion: 'Tu trámite ha finalizado con éxito.'},
  { label: 'Rechazado', color: 'is-danger has-text-white', descripcion: 'Tu proceso de investigación a sido cancelado.'},
]

const StatusTag = ({ status }) => <div className={`tag ${statusColors[status].color}`}>{ statusColors[status].label}</div>

const ProcedureItem = ({ procedure }) => <div className="column is-6">
  <div className="card">
  <header className="card-header">
    <p className="card-header-title">
      Mi trámite
    </p>
    <div className="card-header-icon">
      <div className="tag is-primary has-text-white">
        Enviado el&nbsp; <Moment locale="es" format='DD MMMM YYYY'>{ procedure.payment.createdAt }</Moment>
      </div>&nbsp;
      <StatusTag status={ procedure.status } />
    </div>
  </header>
  <div className="card-content">
    <div className="content">      
      <p className="subtitle">
        { statusColors[procedure.status].descripcion }
      </p>
      <hr/>
    </div>
  </div>
  <footer className="card-footer">
    {
      procedure.status === 2 && <NavLink to={`/panel/informacion/general?procedure=${procedure._id}`} className="card-footer-item has-text-weight-bold is-size-6 has-text-primary">Revisar</NavLink>
    }    
    {
      procedure.status === 3 && <a href={`/descargar/${procedure._id}`} target="_blank" rel="noopener noreferrer" className="card-footer-item has-text-weight-bold is-size-6 has-text-primary">Descargar resumen de investigación</a>
    }
    {
      procedure.status === 3 && <a href={`/detalle/${procedure._id}`} target="_blank" rel="noopener noreferrer" className="card-footer-item has-text-weight-bold is-size-6 has-text-primary">Ver investigación</a>
    }
  </footer>
</div>
</div>

const ProceduresList = ({ procedures }) => procedures && procedures.map( procedure => <ProcedureItem key={ procedure._id } procedure={procedure} /> )

export const All = (props) => {

  const [procedures, setProcedures] = useState(null)

  useEffect(() => {
    getProcedures();
  }, [])

  const getProcedures = async () => {
    let { data } = await GetRenterProcedures();
    setProcedures(data);
  }

  return (
    <div>
      <p className="title is-4 has-text-primary">Trámites en proceso o activos</p>
      <hr/>
      {
        procedures === null && <Loader />
      }

      {      
        procedures?.length === 0 ? <div className="notification is-primary has-text-white">
          No se han encontrado tramites.
        </div> : <div className="columns is-mutltiline">
          <ProceduresList procedures={procedures} />
        </div>
      }
    </div>
  )
}
