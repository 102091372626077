import axios from 'axios';
import { API } from '../../api';

const API_VERSION = API.server

export const CreateUser = (data) => {
  return axios.post(`${API_VERSION}/user/signup`, data)
}

export const AuthUser = (data) => {
  return axios.post(`${API_VERSION}/user/auth`, data)
}

export const ResetPassword = (data) => {
  return axios.post(`${API_VERSION}/user/reset-password`, data)
}

export const ChangePassword = (data) => {
  return axios.post(`${API_VERSION}/user/change-password`, data)
}

/**
 * Log out
 */
export const Logout = () => {
  delete axios.defaults.headers.common.Authorization
  localStorage.clear();
}

/**
 * @param {String} token
 * Set auth header to all requests
 */
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

/**
 * @param {String} token
 * Save token
 */
export const setToken = (token, veryToken = true) => {
  if(veryToken) {
    localStorage.setItem('token', token)
  }
  setAuthToken(token);
}

/**
 * @param {JSON} user
 * Ssave the current user on local storage
 */
export const setCurrentUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}