import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  GetFileURL,
  GetProcedureDetail,
  GetProcedureDetailByToken,
} from "../../services/Renter";
import { EconomyActivity } from "../../components/LessorComponents/EconomyActivity";
import { GeneralInformation } from "../../components/LessorComponents/GeneralInformation";
import { DestinationProperty } from "../../components/LessorComponents/DestinationProperty";
import { References } from "../../components/LessorComponents/References";
import { GuarantorData } from "../../components/LessorComponents/GuarantorData";
import { GuarantorProperty } from "../../components/LessorComponents/GuarantorProperty";
import { setToken } from "../../services/User";

export const Lessor = ({ withToken = false }) => {
  let { id } = useParams();
  if (withToken) {
    setToken(id, false);
  }
  const [procedure, setProcedure] = useState(null);
  const [error, showError] = useState(null);
  useEffect(() => {
    Lessor.getData();
  }, [id]);

  Lessor.getData = async () => {
    if (withToken) {
      let res = await GetProcedureDetailByToken().catch((res) => {
        showError(
          "La investigación solicitada expiro, solicita al usuario genere un nuevo comprobante."
        );
      });
      setProcedure(res?.data);
    } else {
      let res = await GetProcedureDetail(id).catch((res) => {
        showError(
          "La investigación solicitada expiro, solicita al usuario genere un nuevo comprobante."
        );
      });
      setProcedure(res?.data);
    }
  };

  const openFile = async (file) => {
    var windowReference = window.open();

    try {
      let res = await GetFileURL({
        file: file[0],
      });
      windowReference.location = res.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container mt-6">
      {procedure != null && error == null ? (
        <div>
          <div className="box">
            <div className="has-text-centered">
              <img src="/assets/logo-colored.png" alt="" width="130px" />
              <h1 className="title">
                Investigación de:
                <b className="has-text-primary is-uppercase ml-3">
                  {procedure.renter.generalData.firstName} &nbsp;
                  {procedure.renter.generalData.secondName ?? ""} &nbsp;
                  {procedure.renter.generalData.lastName1} &nbsp;
                  {procedure.renter.generalData.lastName2}
                </b>
              </h1>
              <h2 className="subtitle">
                A continuación se muesta toda la información encontrada y
                validada.
              </h2>
            </div>
          </div>
          <GeneralInformation
            generalData={procedure.renter.generalData}
            renter={procedure.renter}
            openFile={openFile}
          />
          <EconomyActivity
            economyActivity={procedure.renter.economyActivity}
            creditHistory={procedure.renter.generalData?.creditHistory ?? []}
            renter={procedure.renter}
            openFile={openFile}
          />
          <DestinationProperty
            destinationProperty={procedure.renter.destinationProperty}
            renter={procedure.renter}
            openFile={openFile}
          />
          <References
            personalReferences={procedure.renter.personalReferences}
          />
          <GuarantorData
            guarantorData={procedure.renter.guarantorData}
            renter={procedure.renter}
            openFile={openFile}
          />
          <GuarantorProperty
            guarantorProperty={procedure.renter.guarantorProperty}
            ownerFile={procedure?.renter?.guarantorData.ownerFile}
            houseDeedsFile={procedure?.renter?.guarantorData.houseDeedsFile}
            registrationTicketFile={
              procedure?.renter?.guarantorData.registrationTicketFile
            }
            predialPaymentFile={
              procedure?.renter?.guarantorData.predialPaymentFile
            }
            isClg={procedure?.type === "CLG"}
            openFile={openFile}
          />
          <hr />
        </div>
      ) : null}

      {error && (
        <section class="hero is-large is-white">
          <div className="hero-body  has-text-centered">
            <img src="/assets/logo.png" alt="" width="130px" />
            <p className="title has-text-centered">
              Investigación no encontrada
            </p>
            <p className="subtitle has-text-centered">{error}</p>
          </div>
        </section>
      )}
    </div>
  );
};
