import React, { useState } from "react";

export const GeneralInformation = ({ generalData, renter, openFile }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="box">
      <p className="title is-5 as-cursor" onClick={() => setOpen(!open)}>
        {open ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-right"></i>
        )}
        Información General
      </p>
      {open && (
        <div>
          {generalData?.notes && (
            <div className="notification">
              Notas de nuestro agente: <br />
              <b>{generalData?.notes}</b>
            </div>
          )}

          <p className="has-text-weight-bold is-size-5">
            Documentación proporcionada:
          </p>
          <div className="buttons">
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.ineFile)}
            >
              INE
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.curpFile)}
            >
              CURP
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.domFile)}
            >
              Comprobante de domicilio
            </button>
            {renter.isBusiness && (
              <>
                {generalData.actFile?.map((f, i) => (
                  <button
                    className="button is-light is-primary"
                    onClick={() => openFile([f])}
                  >
                    Acta {i + 1}
                  </button>
                ))}
                <button
                  className="button is-light is-primary"
                  onClick={() => openFile(generalData.fiscalFile)}
                >
                  Cédula Fiscal
                </button>
                <button
                  className="button is-light is-primary"
                  onClick={() => openFile(generalData.powerFile)}
                >
                  Carta Poder
                </button>
              </>
            )}
          </div>
          <p className="has-text-weight-bold is-size-5">
            Documentación revisada:
          </p>
          <div className="buttons">
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.ineValidationFile)}
            >
              Validación INE
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.curpValidationFile)}
            >
              Validación CURP
            </button>
            <button
              className="button is-light is-primary"
              onClick={() => openFile(generalData.legalHistory)}
            >
              Historial legal
            </button>
          </div>

          <div className="columns">
            <div className="column is-6">
              <p className="has-text-weight-bold is-size-5">
                Información personal
              </p>

              <p className="my-1 is-size-5">
                Nombre (s):
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.firstName} &nbsp;
                  {generalData.secondName ?? ""} &nbsp;
                  {generalData.lastName1} &nbsp;
                  {generalData.lastName2}
                </b>
              </p>
              <p className="my-1 is-size-5">
                CURP:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.curp}
                </b>
              </p>
              <p className="my-1 is-size-5">
                RFC:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.rfc}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Fecha de nacimiento:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.birthdate}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Lugar de nacimiento:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.birthPlace}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Nacionalidad:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.nationality}
                </b>
              </p>
              <p className="my-1 is-size-5">
                Estado civil:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.maritialStatus}
                </b>
              </p>
              {generalData.maritialStatus === "casado" && (
                <>
                  <p className="my-1 is-size-5">
                    Nombre(s) del cónyuge:
                    <b className="has-text-primary is-uppercase ml-3">
                      {generalData.spouseName}
                    </b>
                  </p>
                  <p className="my-1 is-size-5">
                    Nombre(s) del cónyuge:
                    <b className="has-text-primary is-uppercase ml-3">
                      {generalData.spousePhone}
                    </b>
                  </p>
                </>
              )}
            </div>
            <div className="column is-6">
              <p className="has-text-weight-bold is-size-5">Domicilio actual</p>

              <p className="my-1 is-size-5">
                Dirección:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData?.street} &nbsp;
                  {generalData?.number} &nbsp;
                  {generalData?.city} &nbsp;
                  {generalData?.postalCode} &nbsp;
                  {generalData?.suburb} &nbsp;
                  {generalData?.mun} &nbsp;
                  {generalData?.state} &nbsp;
                </b>
              </p>
              <p className="my-1 is-size-5">
                Domicilio actual es:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData.houseStatus}
                </b>
              </p>

              <p className="my-1 is-size-5">
                Tiempo en el domicilio:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData?.timeInHouse}
                </b>
              </p>

              <p className="my-1 is-size-5">
                Nombre del dueño:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData?.houseOwner}
                </b>
              </p>

              <p className="my-1 is-size-5">
                Contacto del dueño:
                <b className="has-text-primary is-uppercase ml-3">
                  {generalData?.houseContact}
                </b>
              </p>
              {generalData?.rentAmmount?.length > 0 && (
                <p className="my-1 is-size-5">
                  Monto de renta (Si aplica):
                  <b className="has-text-primary is-uppercase ml-3">
                    {generalData?.rentAmmount}
                  </b>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
