import styled from "styled-components";

export const Panel = styled.div`
  padding: 20px 20px;
  box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
  border-radius: 8px;
  background-color: white;

  @media only screen and (max-width: 700px) {
    box-shadow: none;
  }
`