import React, { useEffect, useState } from "react";
import { NavLink, Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dialog from "../../components/Dialog";
import { FullScreenLoader } from "../../components/Loader";
import { Navbar, RenterMenu } from "../../components/Navbar";
import { GetCurrentRenter, GetRenterProcedures } from "../../services/Renter";
import { RenterContext } from "../../services/Renter/context";
import Account from "../Acocunt";
import { RenterInformation } from "../RenterInformation";
import { Procedures } from "./Procedures";

/**
 * Validate if all renter data has completed
 * @param {*} renterData
 * @returns Boolean
 */
const informationCompleted = ({
  generalData,
  economyActivity,
  destinationProperty,
  personalReferences,
  guarantorData,
  guarantorProperty,
}) =>
  generalData.completed ||
  economyActivity.completed ||
  destinationProperty.completed ||
  personalReferences.completed ||
  guarantorData.completed ||
  guarantorProperty.completed;

export const RenterPanel = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showCompleteinfoModal, setShowCompleteinfoModal] = useState(false);
  const [renterData, setRenterData] = useState(null);
  const [procedureData, setProcedureData] = useState(null);

  useEffect(() => {
    loadRenterData();
  }, []);

  const loadRenterData = async () => {
    let { data: renterData } = await GetCurrentRenter();
    let { data: procedures = [] } = await GetRenterProcedures();

    if (procedures.length > 0) {
      // has procedures
      for (const procedure of procedures) {
        setProcedureData(procedure);
        //history.push(`/panel/informacion/general?procedure=${procedure._id}`)
        history.push(`/panel/mi-investigacion`);
      }
    } else {
      if (localStorage.getItem("section") == "GUARANTOR_DATA") {
        history.push(`/panel/informacion/fiador`);
      } else {
        history.push(`/panel/informacion`);
      }
    }
    localStorage.setItem("renterID", renterData._id);
    //let inforDialog = localStorage.getItem('inforDialog');
    // setShowCompleteinfoModal(inforDialog === null);
    setRenterData(renterData);
    setLoading(false);
  };
  return (
    <RenterContext.Provider
      value={{
        renterData: renterData,
        informationCompleted: informationCompleted,
        procedureData,
        disabled: procedureData?.status == 3,
      }}
    >
      {loading ? (
        <FullScreenLoader />
      ) : (
        <div>
          <Navbar hideItems={true} shadow={true} />
          <RenterMenu />

          <Switch>
            <Redirect exact from="/panel" to="/panel/mi-investigacion" />
            <Route path="/panel/informacion" component={RenterInformation} />
            <Route path="/panel/mi-investigacion" component={Procedures} />
            <Route path="/panel/mi-cuenta" component={Account} />
          </Switch>

          {
            <Dialog isActive={showCompleteinfoModal} close={null}>
              <div className="notification is-white content">
                <p className="title is-2 has-text-centered">Bienvenido</p>
                <p className="subtitle is-4 has-text-centered">
                  Antes de continuar deberás llenar la información necesaria
                  esto puede tomar unos minutos.
                </p>

                <div className="has-text-centered">
                  <img
                    src="/assets/images/forms.svg"
                    style={{ height: "200px", margin: "0 auto" }}
                    alt="Persona sosteniendo formularios."
                  />
                </div>

                <br />
                <div className="has-text-centered">
                  <NavLink
                    onClick={() => {
                      localStorage.setItem("inforDialog", true);
                      setShowCompleteinfoModal(false);
                    }}
                    className="button is-primary is-medium"
                    to={window.location.pathname}
                  >
                    <span>Continuar</span>
                    <span className="icon">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </NavLink>
                </div>
              </div>
            </Dialog>
          }
        </div>
      )}
    </RenterContext.Provider>
  );
};
