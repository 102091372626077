import React from 'react';
import styled from 'styled-components';
const DialogStyle = styled.div`
  transition: all 0.3s ease;
  display: flex !important;
  visibility: hidden;
  
  h1, h2 {
    text-align: center;
  }

  &.is-active{
    display: flex !important;
    visibility: visible;
  }

  .modal-content{
    opacity: 0;
    display: felx;
    justify-content: center;
    transform: translateY(50px);
    transition: all 0.3s ease;
    width: ${(props) => props.width};
    overflow: hidden;

    .notification.content {
      overflow: auto;
      max-height: 90vh;
    }
  }

  .modal-background {
    transition: all 0.3s ease;
    opacity: 0;
    background: rgba(0, 5, 33, 0.15) !important;
    backdrop-filter: blur(10px);
  }

  &.is-active {
    .modal-content{
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease;
    }

    .modal-background {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .actions{
    justify-content: flex-end;
    display: flex;

    .button{
      margin-left: 8px;
    }
  }

  @media only screen and (max-width: 800px) {
    .modal-content {
      max-width: 90% !important;
      .notification {
        max-width: 100% !important;
      }
    }
  }
`;

const Dialog = (props) => {
  const {
    children,
    isActive,
    close,
    width,
    footer,
  } = props;
  return (
    <DialogStyle className={`modal ${isActive ? 'is-active' : 'desabled'}`} width={width}>
      <div className="modal-background" />
      <div className="modal-content">
        { children }
      </div>
      { footer }

      {
        close ? <button type="button" className="modal-close is-large" aria-label="close" onClick={close} /> : null
      }
    </DialogStyle>
  );
};

export default Dialog;


export const ConfirmDialog = ({
    showDialog,
    close,
    title,
    body,
    cancelAction,
    agreeAction,
    children
  }) => {
  return <Dialog
    width="400px"
    isActive={showDialog}
    close={close}    
  >
    <div className="notification is-dark">
      <h1 className="title">
        { title }
      </h1>
      <p>
        { children }
      </p>
      <hr/>    
      <button className="button is-fullwidth is-success" onClick={agreeAction}>Aceptar</button>
      <br/>
      <button className="button is-fullwidth is-light" onClick={cancelAction}>Cancelar</button>
    </div>
  </Dialog>
}