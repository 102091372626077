import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import { setToken } from "../../services/User";
import { NotFound } from "../NotFound";
import AuthContext, { AuthStatus } from "../../services/authService/context";
import { PrivateRoute } from "../../services/authService";
import { SignUp } from "../SignUp";
import { ForgotPassword } from "../ForgotPassword";
import { SignIn } from "../SignIn";
import { SignOut } from "../SignOut";
import { CheckoutPage } from "../CheckoutPage";
import { RenterPanel } from "../RenterPanel";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import GenPdf from "../GenPdf";
import { Lessor } from "../Lessor";
import { Footer } from "../../components/Footer";
import { WhatsAppButton } from "../../components/WhatsAppButton";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROD);

function useQuey() {
  return new URLSearchParams(window.location.search);
}

export const Root = (props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Uninitialized);
  const [currentUser, setUser] = useState(null);
  const [hasProcedure, setProcedure] = useState(null);
  let query = useQuey();
  let redirect = query.get("showLandingPage");

  useEffect(() => {
    validateSession();
  }, []);

  const validateSession = () => {
    let token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const dateNow = new Date();
      if (decoded.exp * 1000 < dateNow) {
        setAuthStatus(AuthStatus.Unauthenticated);
      } else {
        setAuthStatus(AuthStatus.Authenticated);
        setToken(token);
        setUser(decoded);
        let procedure = JSON.parse(localStorage.getItem("procedure"));
        setProcedure(procedure);
      }
    } else {
      setAuthStatus(AuthStatus.Unauthenticated);
    }
  };

  const RenderPanel = (props) => {
    if (currentUser === null) return;

    let { userType } = currentUser;
    switch (userType) {
      case 0:
        return <RenterPanel />;
      default:
        return null;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="root">
        {authStatus === AuthStatus.Uninitialized ? (
          <div>Cargando</div>
        ) : (
          <AuthContext.Provider
            value={{
              currentUser: currentUser,
              authStatus: authStatus,
            }}
          >
            <Router>
              <Switch>
                {!redirect && authStatus === 2 ? (
                  <Redirect exact from="/" to="/panel" />
                ) : null}
                <Route
                  exact
                  path="/"
                  component={() => <Redirect exact from="/" to="/acceder" />}
                />
                <Route exact path="/acceder" component={SignIn} />
                <Route exact path="/crear-cuenta" component={SignUp} />
                <Route
                  exact
                  path="/recuperar-contrasena"
                  component={ForgotPassword}
                />
                <PrivateRoute
                  exact
                  path="/cerrar-sesion"
                  component={SignOut}
                  authStatus={authStatus}
                />
                <PrivateRoute
                  exact
                  path="/pagar"
                  component={CheckoutPage}
                  authStatus={authStatus}
                />
                {!redirect && authStatus === 2 ? (
                  <Redirect exact from="/" to="/panel" />
                ) : null}
                <PrivateRoute
                  path="/panel"
                  component={RenderPanel}
                  authStatus={authStatus}
                />
                <PrivateRoute
                  path="/descargar/:id"
                  component={GenPdf}
                  authStatus={authStatus}
                  exact
                />

                <Route exact path="/detalle/:id" component={Lessor} />

                <Route
                  exact
                  path="/investigacion/:id"
                  component={() => <Lessor withToken={true} />}
                />

                <Route component={NotFound} />
              </Switch>
            </Router>
          </AuthContext.Provider>
        )}
        <Footer />
      </div>
      <WhatsAppButton />
    </Elements>
  );
};
