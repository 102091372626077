import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CreateUser, setCurrentUser, setToken } from "../../services/User";
import { GetReferencedBy } from "../../services/Renter";

export const SignUpForm = (props) => {
  let userType = new URLSearchParams(window.location.search).get("type");
  let preEmail = new URLSearchParams(window.location.search).get("email");
  const referencedBy = new URLSearchParams(window.location.search).get(
    "reference"
  );

  const [type, setType] = useState(parseInt(userType) || 0);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyNotice, setPrivacyNotice] = useState(false);
  const [referencedByData, setReferencedByData] = useState(null);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(preEmail || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [nHouses, setnHouses] = useState(1);
  const [realStateName, setRealStateName] = useState("");

  const [showErrors, setShowErrors] = useState(false);

  const [signUpError, setSignUpError] = useState("");
  const [tooManyRequest, setTooManyRequest] = useState(null);

  useEffect(() => {
    localStorage.setItem("reference", referencedBy);
    getRealStateData();
  }, []);

  const getRealStateData = async () => {
    const { data } = await GetReferencedBy(referencedBy);
    if (data) {
      setReferencedByData(data);
    }
  };

  const signIn = (e) => {
    e.preventDefault();
    if (!termsAndConditions && !privacyNotice) {
      setShowErrors(true);
    } else {
      switch (type) {
        // TODO: Add missing user typesx
        case 0:
          createUser();
          break;
        default:
          break;
      }
    }
  };

  const createUser = async () => {
    try {
      let { data } = await CreateUser({
        firstName,
        lastName,
        email: email.toLowerCase(),
        password,
        phone,
        referencedBy,
        type,
      });

      setToken(data.token);
      setCurrentUser(data.userData);
      window.location.href = "/";
    } catch (error) {
      let { data } = error.response;

      if (error.response.status === 429) {
        setTooManyRequest(data);
      }

      if (data?.error?.code === 11000) {
        let dups = Object.keys(data?.error?.keyValue);
        setSignUpError(dups);
        setShowErrors(true);
      }
    }
  };
  return (
    <div>
      <div className="has-text-centered">
        <img src="/assets/logo.png" alt="Invix" className="logo is-centered" />
      </div>
      <br />
      <p className="title is-3 has-text-centered">Crear cuenta</p>

      <div className="tabs is-toggle is-fullwidth">
        <ul>
          <li>
            <a
              href="#inquilino"
              className={type === 0 ? "is-active" : ""}
              onClick={() => setType(0)}
            >
              <span>Inquilino</span>
            </a>
          </li>
          {/* <li>
            <a href="#arrendador" className={type === 1 ? 'is-active' :''} onClick={() => setType(1)}>
              <span>Arrendador</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#inmobiliaria" className={type === 2 ? 'is-active' :''} onClick={() => setType(2)}>
              <span>Inmobiliaria</span>
            </a>
          </li> */}
        </ul>
      </div>
      {signUpError !== "" && (
        <>
          <div className="notification is-danger has-text-white">
            Uno o más campos ya están registrados. favor de validarlos o{" "}
            <Link
              to="/recuperar-contrasena"
              className="has-text-weight-bold is-underlined"
            >
              recupera tu contraseña
            </Link>
          </div>
          <br />
        </>
      )}
      {tooManyRequest !== null && (
        <>
          <div className="notification is-danger has-text-white">
            {tooManyRequest}
          </div>
          <br />
        </>
      )}
      <form onSubmit={signIn}>
        {type === 2 ? (
          <div className="field">
            <label className="label">Nombre de la Inmobiliaria</label>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Nombre"
                value={realStateName}
                onChange={(e) => setRealStateName(e.target.value)}
              />
            </div>
            {!realStateName && showErrors ? (
              <p className="help is-danger">Este campo es obligatorio</p>
            ) : null}
          </div>
        ) : null}

        {type === 1 ? (
          <div className="field">
            <label className="label">¿Cuantas casas tienes?</label>
            <div className="control">
              <input
                className="input is-medium"
                type="number"
                placeholder="1-3"
                max="3"
                min="1"
                value={nHouses}
                onChange={(e) => setnHouses(e.target.value)}
              />
            </div>
            {!nHouses && showErrors ? (
              <p className="help is-danger">Este campo es obligatorio</p>
            ) : null}
          </div>
        ) : null}

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Nombre (s)</label>
              <div className="control">
                <input
                  className="input is-medium"
                  type="text"
                  placeholder="Nombre"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              {!firstName && showErrors ? (
                <p className="help is-danger">Este campo es obligatorio</p>
              ) : null}
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Apellido(s)</label>
              <div className="control">
                <input
                  className="input is-medium"
                  type="text"
                  placeholder="Apellido(s)"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              {!lastName && showErrors ? (
                <p className="help is-danger">Este campo es obligatorio</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Correo electrónico</label>
          <div className="control">
            <input
              className="input is-medium"
              type="email"
              placeholder="ejemplo@ejemplo.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {!email && showErrors ? (
            <p className="help is-danger">Este campo es obligatorio</p>
          ) : null}
        </div>

        <div className="field">
          <label className="label">Contraseña</label>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              placeholder="***"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {!password && showErrors ? (
            <p className="help is-danger">Este campo es obligatorio</p>
          ) : null}
        </div>

        <div className="field">
          <label className="label">Confirmar contraseña</label>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              placeholder="***"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {password !== confirmPassword && confirmPassword !== "" ? (
            <p className="help is-danger">La contraseña no coincide</p>
          ) : null}
        </div>

        <div className="field">
          <label className="label">Teléfono</label>
          <div className="control">
            <input
              className="input is-medium"
              type="tel"
              minLength="10"
              maxLength="10"
              value={phone}
              placeholder="10 Dígitos"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          {!phone && showErrors ? (
            <p className="help is-danger">Este campo es obligatorio</p>
          ) : null}
          {signUpError.indexOf("phone") !== -1 && showErrors ? (
            <p className="help is-danger">Este campo ya está registrado</p>
          ) : null}
        </div>

        <label className="checkbox">
          <input
            type="checkbox"
            checked={termsAndConditions}
            onChange={(e) => setTermsAndConditions(e.target.checked)}
          />
          &nbsp; Acepto los{" "}
          <a
            className="is-underlined"
            href="/assets/docs/terminos_condiciones.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            terminos y condiciones{" "}
          </a>
          {!termsAndConditions && showErrors ? (
            <p className="help is-danger">
              Debes acetar los terminos y condiciones
            </p>
          ) : null}
        </label>
        <br />

        <label className="checkbox">
          <input
            type="checkbox"
            checked={privacyNotice}
            onChange={(e) => setPrivacyNotice(e.target.checked)}
          />
          &nbsp; Acepto el{" "}
          <a
            className="is-underlined"
            href="/assets/docs/aviso_de_privacidad.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            aviso de privacidad
          </a>
          {!privacyNotice && showErrors ? (
            <p className="help is-danger">
              Debes acetar el aviso de privacidad
            </p>
          ) : null}
        </label>
        <br />
        <br />
        <button className="button is-primary is-fullwidth" type="submit">
          Aceptar
        </button>
      </form>
      <br />
    </div>
  );
};
