import React from 'react'
import { useState } from 'react';
import Dialog from '../../components/Dialog';
import { ChangePassword, Logout } from '../../services/User';

export const Account = (props) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [userData, setUserData] = useState(currentUser)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')


  const updatePassword = async () => {
    let res = await ChangePassword({
      password,
      newPassword,
    });
    alert('Contraseña actualizada debe inicar sesión de nuevo')
    Logout();
    window.location.href = '/'
  }

  return (
    <Dialog isActive={true} close={ () => {
      props.history.goBack();
    }}>
      
      <div className="notification is-white">
        <h1 className="title ">Datos de la cuenta</h1>
        <div className="columns is-multiline">
          <div className="column">
            <div className="field">
              <label className="label">Nombres(e)</label>
              <div className="control">
                <input className="input is-medium" type="text" placeholder="Nombre" value={userData.firstName} onChange={(e) => setUserData({  ...userData, firstName: e.target.value})} disabled/>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Apellido(s)</label>
              <div className="control">
                <input className="input is-medium" type="text" placeholder="Apellido(s)" value={userData.lastName} onChange={(e) => setUserData({  ...userData, lastName: e.target.value})} disabled/>
              </div>
            </div>
          </div>
          <div className="column is-12">
            <div className="field">
              <label className="label">Correo electrónico</label>
              <div className="control">
                <input className="input is-medium" type="email" placeholder="ejemplo@ejemplo.com" value={userData.email} disabled/>
              </div>
            </div>
          </div>

          <div className="column is-12">
            <h1 className="subtitle">Actualizar contraseña</h1>
            <div className="field">
              <label className="label">Contraseña actual</label>
              <div className="control">
                <input className="input is-medium" type="password" placeholder="Contrasela actual" value={password} onChange={({ target: { value } }) => setPassword(value)}/>
              </div>
            </div>
          </div>
          <div className="column is-12">
            <div className="field">
              <label className="label">Contraseña nueva</label>
              <div className="control">
                <input className="input is-medium" type="password" placeholder="Contraseña nueva" value={newPassword} onChange={({ target: { value } }) => setNewPassword(value)}/>
              </div>
            </div>
            <button className="button is-fullwidth is-primary" onClick={updatePassword}>
              Cambiar contraseña
            </button>
          </div>
        </div>      
      </div>
  </Dialog>
  )
}


export default Account;