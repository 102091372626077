import React, { useState, useContext, useEffect } from 'react'
import { CreateReference, GetPersonalReferences } from '../../../../services/Renter'
import { RenterInformationContext } from '../../../../services/Renter/context'
import { ReferenceForm } from './referenceForm';

export const PersonalReferences = (props) => {
  const { updateReferenceData }=  props;
  const { completetSection, isFieldWithIssues } = useContext(RenterInformationContext);

  const [personalReferences, setPersonalReferences] = useState(props.personalReferences || [])

  useEffect(() => {
    async function getData() {
      let { data } = await GetPersonalReferences()
      console.log('Log at 👉  ~ file: index.jsx ~ line 15 ~ getData ~ data', data);
      setPersonalReferences(data);
    }
    getData();
  }, [props.history])

  const next = () => {
    if (personalReferences.length > 2) {
      completetSection('personalReferences', personalReferences);
      return props.history.push('/panel/informacion/fiador');
    }
  }

  const addReference = async () => {
    let { data: { personalReference} } = await CreateReference({
      name: '',
      job: '',
      relationship: '',
      email: '',
      phone: ''
    })
    setPersonalReferences(personalReferences.concat(personalReference));

    if (personalReferences.length >= 2) {
      completetSection('personalReferences', personalReferences);
    }
  }

  return (
    <div>
      <div className="navbar" >
        <div className="navbar-start">
          <div>
            <p className="title is-4 has-text-primary">Referencias personales</p>
            <p className="subtitle is-5">Los datos se guardan automáticamente</p>
            <p className="subtitle is-5 has-text-info">Debe registrar mínimo 2 referencias</p>
          </div>
        </div>
        <div className="navbar-end">
          <button className="button is-primary is-fullwidth-mobile" onClick={next}>
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>   
      <hr/>
      {
        personalReferences.map((reference, index) => <ReferenceForm key={index} reference={reference} index={index} updateReferenceData={updateReferenceData}/>)
      }
      <hr/>
      <button className="button is-primary is-light is-large is-fullwidth" onClick={addReference}>
        <span>
          Agregar referencia
        </span> 
        <span className="icon">
          <i className="fa fa-plus"></i>
        </span>
      </button>
      <div className="navbar" >
        <div className="navbar-start">
        </div>
        <div className="navbar-end">
          <button className="button is-primary is-fullwidth-mobile" onClick={next}>
            <span>Siguiente</span>
            <span className="icon">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>   
    </div>
  )
}
