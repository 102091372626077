import React, { useEffect, useState } from "react";
import { AuthNip, SendNip } from "../../services/Renter";
import Dialog from "../Dialog";
import { Input } from "../Form";

export const CreditHistoryAuth = ({ show, close }) => {
  const [nipConfirmation, setNipConfirmation] = useState("");
  const [nip, setNip] = useState('')
  const [showNipError, setShowNipError] = useState(false)
  const sendNip = async () => {
    const { data } = await SendNip();
    setNip(data?.nip);
  };

  useEffect(() => {
    sendNip();
  }, []);

  const vaidateCode = async () => {
    if (nip != nipConfirmation) {
      setShowNipError(true);
      return;
    }

    await AuthNip({
      date: Date.now(),
      nip,
    })
    close(false)
  }

  return (
    <>
      <Dialog isActive={show} close={() => close(false)}>
        <div className="notification is-white has-text-centered overflow-auto">
          <p className="title is-2 has-text-centered">
            Consulta de historial crediticio
          </p>
          <p className="subtitle is-5 has-text-justified m-3">
            Autorizo expresamente a Acción Legal e Inmobiliaria S.A. de C.V.
            (invix mx) para que lleve a cabo investigaciones sobre mi
            comportamiento Crediticio en las Sociedades de Información
            Crediticia (SIC) que estime conveniente. Declaro que conozco la
            naturaleza y el alcance de la información que se solicitará, el uso
            que se le dará y que se pondrán realizar consultas periódicas de mi
            historial crediticio. Consiento que esta autorización tenga una
            vigencia de 3 años contados a partir de hoy, y en su caso mientras
            mantengamos relación jurídica. Así mismo, acepto que este documento
            quede bajo propiedad de la SIC consultada, para efectos de control y
            cumplimiento del artículo 28 de la LRSIC.
          </p>
          <Input
            label="Código de confirmación"
            type="number"
            placeholder="Código de confirmación"
            inputClass="is-transparent is-borderless is-shadowless"
            value={nipConfirmation}
            onChange={({ target: { value } }) =>
              setNipConfirmation(value)
            }
            helper="Introduce el código que enviamos a tu teléfono, puede demorar hasta 3 minutos en llegar."
            error="El código es incorrecto"
            showError={showNipError}
          />
          <button
            className="button is-primary is-fullwidth-mobile"
            onClick={vaidateCode}
          >
            <span>Autorizar</span>
          </button>
          <button
            className="button is-light is-fullwidth-mobile"
            onClick={sendNip}
          >
            <span>Enviar de nuevo</span>
          </button>
        </div>
      </Dialog>
    </>
  );
};
