import React, {  } from 'react'
import { Padding } from '../Padding'
const sections = {
    generalData: {  
      ineFile: 'INE',
      domFile: 'Comprobante de domicilio',
      actFile: 'Acta constitutiva',
      fiscalFile: 'Cédula fiscal',
      powerFile: 'Carta poder',
      label: 'Información General',
      fullName: 'Nombre',
      birthdate: 'Fecha de nacimiento',
      birthPlace: 'Lugar de nacimiento',
      address: 'Domicilio',
      rfc: 'RFC',
      curp: 'CRUP',
      nationality: 'Nacionalidad',
      maritialStatus: 'Estado civil',
      spouseName: 'Nombre del conyuge',
      spousePhone: 'Teléfono del conyuge',
      houseStatus: 'Estado del domiclio actual',
      otherStatus: 'Estado del domiclio actual (Especifíque)',
      rentAmmount: 'Monto de renta',
      timeInHouse: 'Tiempo en el domicilio',
      houseOwner: 'Nombre del dueño',
      houseContact: 'Contacto del dueño',
      businessName: 'Razón social',
      businessNationality: 'Nacionalidad',
    },
    economyActivity: {   
      entryFile: 'Comprobante de ingresos',
      label: 'Actividad Económica',
      mainEntry: 'Fuente principal de ingresos',
      workSource: 'Fuente de trabajo',
      businessDesc: 'Descripción del trabajo',
      pensionSource: 'Fuente de pensión',
      other: 'Fuente principal de ingresos(Otro)',
      timeInJob: 'Tiempo en el trabajo',
      address: 'Domiclio del trabajo',
      jobPhone: 'Teléfono del trabajo',
      website: 'Sitio web',
      mensualEntry: 'Ingreso mensual neto',
      otherEntry: 'Otros ingresos',
      otherEntryName: 'Nombre de otro ingreso',
      otherEntryAmmount: 'Monto de otros ingresos',
      totalMonthEntry: 'Total de ingresos',
      totalMonthExpenses: 'Gastos del mes',
      mainActivity: 'Actividad principal',
    },
    destinationProperty: {    
      label: 'Destino del inmuble',
      usage: 'Uso del inmueble',
      totalPeople: 'Total de habitantes',
      children: 'Niños',
      adults: 'Adultos',
      olderAdults: 'Adulto mayor',
      hasPets: 'Tiene mascotas',
      petType: 'Tipo de mascota',
      habitationalName: 'Nombre de quién habitará el inmueble',
    },
    personalReference: {
      label: 'Referencias personales',
    },
    guarantorData: {    
      label: 'Datos del fiador',
      name: 'Nombre',
      birthPlace: 'Lugar de nacimiento',
      birthdate: 'Fecha de nacimiento',
      rfc: 'RFC',
      curp: 'CRUP',
      nationality: 'Nacionalidad',
      maritialStatus: 'Estado civil',
      job: 'Trabajo',
      spouseName: 'Nombre del conyuge',
      spousePhone: 'Teléfono del conyuge',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      address: 'Dirección',
      businessName: 'Razón social',
      businessRFC: 'RFC (Empresa)',
      businessAddress: 'Dirección de la empresa',
      businessNationality: 'Nacionalidad de la empresa',
      businessPhone: 'Teléfono de la empresa',
    },
    guarantorProperty: {
      ownerFile: 'Comprobante de titularidad del inmueble',
      label: 'Propiedad del fiador',
      address: 'Domicilio',
      assessment: 'Reporta gravamen?',
      assessmentName: 'Tipo de gravamen',
    }
}
export const Issues = ({issues}) => {

  const issueItem = (issue) => {

    if (issue.section === 'personalReference') {
      return <div key={issue._id} className="">
        <p key={issue._id}  className="has-text-danger">
        · Hay datos incompletos en la sección:  { sections[issue.section].label };
        <br/>
        <b>{ issue.description }</b>
        </p> 
      </div>
    }

    return <div key={issue._id} className="">
      <p key={issue._id}  className="has-text-danger">
        - Hay un error en la sección <b>{ sections[issue.section].label }</b>, está incompleto el campo <b>{ sections[issue.section][issue.field] }</b>.
        <br/>
        &nbsp; &nbsp; &nbsp;Comentarios: <b>&nbsp;{ issue.description }</b>
      </p> 
      <br/>
    </div>
  }

  return (
    <Padding padding="10px 0px">
      { 
        issues.map(i => issueItem(i))
      }
      {/* <div className="has-text-right">
        <button className="button is-primary is-light has-text-weight-bold" onClick={() => notifyInformation()}>
          Da clic a aquí cuando hayas completado la información.
        </button>
      </div> */}
    </Padding>
  
  )
}

export default Issues;

