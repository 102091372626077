import React from 'react'
import styled from 'styled-components'
import Icon from './icon.svg';

const WhatsAppButtonStyle = styled.a`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 30px;
  right: 30px;
  background-color: #25D043;
  display: grid;
  place-content: center;
  border-radius: 50%;
`;

export const WhatsAppButton = () => {
  return (
    <WhatsAppButtonStyle href="https://wa.me/523329213210" target="_blank" rel="noopener noreferrer">
      <img src={Icon} width="25"/>
    </WhatsAppButtonStyle>
  )
}
